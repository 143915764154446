const LOCAL_PART_ATOM = '[a-z0-9!#$%&\'*+/=?^_`{|}~\u0080-\uFFFF-]';
const LOCAL_PART_INSIDE_QUOTES_ATOM = "(?:[a-z0-9!#$%&'*.(),<>\\[\\]:;  @+/=?^_`{|}~\u0080-\uFFFF-]|\\\\\\\\|\\\\\\\")";
const LOCAL_PART_PATTERN = new RegExp(`(?:${LOCAL_PART_ATOM}+|"${LOCAL_PART_INSIDE_QUOTES_ATOM}+")`
    + `(?:\\.(?:${LOCAL_PART_ATOM}+|"${LOCAL_PART_INSIDE_QUOTES_ATOM}+"))*`, 'i');
const DOMAIN_CHARS_WITHOUT_DASH = '[a-z\\u0080-\\uFFFF0-9!#$%&\'*+/=?^_`{|}~]';
const DOMAIN_LABEL = `(?:${DOMAIN_CHARS_WITHOUT_DASH}-*)*${DOMAIN_CHARS_WITHOUT_DASH}`;
const DOMAIN = `^${DOMAIN_LABEL}+(?:\\.${DOMAIN_LABEL}+)*$`;
const DOMAIN_PATTERN = new RegExp(DOMAIN, 'i');

/**
 * Checks if a string is a valid email address. Leading and trailing padding whitespace is tolerated.
 * The regexp patterns used here are inspired from org.hibernate.validator.internal.constraintvalidators.AbstractEmailValidator.
 * @param {string} value the string to check
 * @returns {boolean} true if valid, false otherwise
 */
export default function isValidEmailAddress(value) {
  if (!value) {
    return false;
  }
  const valueStr = value.toString().trim();
  const splitPos = valueStr.lastIndexOf('@');
  if (splitPos < 0) {
    return false;
  }
  const localPart = valueStr.substring(0, splitPos);
  if (!RegExp(LOCAL_PART_PATTERN).exec(localPart)) {
    return false;
  }
  const domainPart = valueStr.substring(splitPos + 1);
  return !!RegExp(DOMAIN_PATTERN).exec(domainPart);
}
