import { defineStore } from 'pinia';
import useGlobalStore from './globalStore';
import useMfaStore from './mfaStore';
import baseUrls from '../router/config/baseUrls';
import useConsentStore from './consentStore';
import useViewStore from './viewStore';

const { apiUrl } = baseUrls;

export default defineStore('verifyAccountStore', {
  state: () => ({
    verifyPhoneNumber: '',
  }),
  getters: {
    getVerifyPhoneNumber: (state) => state.verifyPhoneNumber,
  },
  actions: {
    setVerifyPhoneNumber(phoneNumber) {
      this.verifyPhoneNumber = phoneNumber;
    },
    async verifyAccount() {
      const { setRequest } = useGlobalStore();

      const response = await setRequest({
        method: 'GET',
        url: `${apiUrl}/verifyAccount`,
      });

      return response;
    },
    async requestVerificationCode() {
      const { setRequest } = useGlobalStore();

      const response = await setRequest({
        method: 'POST',
        url: `${apiUrl}/verifyAccount/newCode`,
      });

      return response;
    },
    async verifyPhoneCode(payload) {
      const { setRequest } = useGlobalStore();
      const viewStore = useViewStore();
      const customAlerts = {
        PHONE_VERIFICATION_CODE_MISMATCH: 'warning',
        PERMANENT_MFA_SETUP_REQUIRED: 'warning',
        PERMANENT_MFA_SETUP_REQUIRED_RESET_PASSWORD: 'warning',
        MFA_MAX_LIMIT_REACHED: 'error',
      };

      const response = await setRequest(
        {
          method: 'POST',
          url: `${apiUrl}/verifyAccount`,
          payload,
        },
        customAlerts,
      );

      const { responseStatus, customerMfaInfo, consentTypeList } = response;
      const { type } = responseStatus;

      if (type === 'PERMANENT_MFA_SETUP_REQUIRED' || type === 'PERMANENT_MFA_SETUP_REQUIRED_RESET_PASSWORD' || type === 'MFA_MAX_LIMIT_REACHED') {
        viewStore.setCurrentView('signIn', false);
      }
      // set or clear MFA data
      const { setInitMfaData } = useMfaStore();
      setInitMfaData({ type, customerMfaInfo, consentTypeList });

      const consentStore = useConsentStore();
      consentStore.setReconsentTypeList(consentTypeList);

      return response;
    },
    async updatePhoneNumber(payload) {
      const { setRequest } = useGlobalStore();

      const response = await setRequest({
        method: 'POST',
        url: `${apiUrl}/verifyAccount/setPhone`,
        payload,
      });

      return response;
    },
  },
});
