<script setup>
/* Imports */
import { useRouter } from 'vue-router';
import useGlobalStore from '../../store/globalStore';
import useViewStore from '../../store/viewStore';
import useMfaStore from '../../store/mfaStore';

import PortalPrimaryHeader from '../../components/PortalPrimaryHeader/PortalPrimaryHeader.vue';
import PortalCopyCon from '../../components/PortalCopyCon/PortalCopyCon.vue';
import PortalButton from '../../components/PortalButton/PortalButton.vue';
import PortalFooterCreateAccount from '../PortalFooterCreateAccount/PortalFooterCreateAccount.vue';

/* Router */
const router = useRouter();

/* Store */
const globalStore = useGlobalStore();
const viewStore = useViewStore();
const mfaStore = useMfaStore();

/* Props */
const props = defineProps({
  pageHeader: {
    type: String,
    default: '',
  },
});

const handleNext = (e) => {
  e.preventDefault();
  viewStore.setCurrentView('mfaOptions');
};

const handleBack = async (e) => {
  e.preventDefault();
  if (globalStore.getGlobalFlow === 'createAccount') {
    router.push({ name: 'CreateAccount' });
  } else {
    router.push({ name: 'SignIn' });
  }
};
</script>

<template>
  <div class="mfa-required">
    <Portal-Primary-Header
      :title="props.pageHeader"
    />
    <g-copy
      type="large"
    >
      {{ globalStore.translations.setupMfaRequiredContentTitle }}
    </g-copy>
    <Portal-Copy-Con>
      <g-copy
        type="large"
      >
        {{ mfaStore.getPermMFAEmailSetup ? globalStore.translations.setupMfaEmailRequiredContent:
          globalStore.translations.setupMfaRequiredContent }}
      </g-copy>
    </Portal-Copy-Con>
    <div>
      <PortalButton
        theme="ocean-blue"
        size="large"
        data-testid="next-btn"
        @click="handleNext"
      >
        {{ globalStore.translations.generalButtonNext }}
      </PortalButton>
      <PortalButton
        size="large"
        flat
        data-testid="back-btn"
        @click="handleBack"
      >
        {{ globalStore.translations.generalButtonGoBack }}
      </PortalButton>
    </div>
  </div>
  <Portal-Footer-Create-Account />
</template>

<style lang="scss">
.mfa-required {
  margin-bottom: 3em;
}
</style>
