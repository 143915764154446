<script setup>
/* Imports */
import useGlobalStore from '../../store/globalStore';

import PortalFooter from '../../components/PortalFooter/PortalFooter.vue';

/* Store */
const globalStore = useGlobalStore();
</script>

<template>
  <Portal-Footer>
    {{ globalStore.translations.generalLoginAlreadyHaveAnAccount }}
    <router-link
      :to="{ name: 'SignIn' }"
    >
      {{ globalStore.translations.generalButtonSignIn }}
    </router-link>
  </Portal-Footer>
</template>
