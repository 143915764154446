<script setup>
/* Imports */
import { onMounted, reactive } from 'vue';
import useGlobalStore from '../../store/globalStore';
import useClientRegistriesStore from '../../store/clientRegistriesStore';
import useSigninStore from '../../store/signinStore';
import useFormValidationStore from '../../store/formValidationStore';
import useViewStore from '../../store/viewStore';

import ValidationForm from '../../components/ValidationForm/ValidationForm.vue';
import PortalPrimaryHeader from '../../components/PortalPrimaryHeader/PortalPrimaryHeader.vue';
import ValidationInput from '../../components/ValidationInput/ValidationInput.vue';
import PortalFooterCreateAccount from '../PortalFooterCreateAccount/PortalFooterCreateAccount.vue';

/* Store */
const globalStore = useGlobalStore();
const clientRegistriesStore = useClientRegistriesStore();
const signInStore = useSigninStore();
const formValidationStore = useFormValidationStore();
const viewStore = useViewStore();

/* State */
const state = reactive({
  lockToEmailAddress: clientRegistriesStore.getLockToEmailAddress,
  emailInput: clientRegistriesStore.getLockToEmailAddress || '',
  validation: {},
  formLoading: false,
  readOnlyEmailInput: false,
});

/**
 * If getLockToEmailAddress is true, set email input to readOnly
 *  and trigger validation
 */
onMounted(() => {
  if (state.lockToEmailAddress) {
    state.readOnlyEmailInput = true;
    const emailInput = document.getElementById('email');
    emailInput.focus();
    emailInput.blur();
  }
});

/* Handle submit the form */
const handleSubmit = async () => {
  state.formLoading = true;
  const response = await signInStore.setForgotPassword({
    emailAddress: state.emailInput,
  });
  state.formLoading = false;

  const { responseStatus } = response;
  const { type } = responseStatus;

  if (type === 'FORGOT_PASSWORD_UNEXPECTED_ERROR' || type === 'TEMP_PASSWORD_REQUESTED') {
    localStorage.setItem('forgotPasswordEmail', state.emailInput);
    viewStore.setCurrentView('signIn', false);
  }
};

/* Validation of the form */
const emailValidation = [formValidationStore.getIsRequired, formValidationStore.getIsEmail];

const validateInput = (name, invalid) => {
  state.validation[name] = invalid;
};

/* Cancel the action */
const cancelBtn = () => {
  viewStore.setCurrentView('signIn');
};
</script>

<template>
  <div class="forgot-password">
    <Portal-Primary-Header
      :title="globalStore.translations.forgotPswPageTitle"
    />
    <g-copy
      type="large"
    >
      {{ globalStore.translations.generalLoginResetPasswordDesc }}
    </g-copy>
    <Validation-Form
      :submit-form="handleSubmit"
      :submit-btn-text="globalStore.translations.generalButtonRecoverPassword"
      :cancel-btn-text="globalStore.translations.generalButtonGoBack"
      :cancel-btn="cancelBtn"
      :validation="state.validation"
      :loading="state.formLoading"
    >
      <fieldset>
        <Validation-Input
          id="email"
          v-model.trim="state.emailInput"
          :validation-rules="emailValidation"
          :label-text="globalStore.translations.generalTextEmailOrUsername"
          required
          name="email"
          type="email"
          :read-only="state.readOnlyEmailInput"
          @validate-input="validateInput"
        />
      </fieldset>
    </Validation-Form>
    <Portal-Footer-Create-Account />
  </div>
</template>
