<script setup>
/* Imports */
import { reactive, computed } from 'vue';
import useGlobalStore from '../../store/globalStore';
import useFormValidationStore from '../../store/formValidationStore';

import ValidationInput from '../../components/ValidationInput/ValidationInput.vue';

/* Store */
const globalStore = useGlobalStore();
const formValidationStore = useFormValidationStore();

/* Props */
const props = defineProps({
  validationRules: {
    type: Array,
    default() {
      return [];
    },
  },
  labelText: {
    type: String,
    default: '',
  },
  helpText: {
    type: String,
    default: '',
  },
  validateOnKeyUp: {
    type: Boolean,
    default: false,
  },
});

/* Password validation */
const passwordValidation = [formValidationStore.getIsRequired, ...props.validationRules];

/* Handle show-hide password */
const state = reactive({
  passwordFieldType: 'password',
});

const handleShowPassword = () => {
  state.passwordFieldType = state.passwordFieldType === 'password' ? 'text' : 'password';
};

const toggleFloatingLabelName = computed(() => (state.passwordFieldType === 'password'
  ? globalStore.translations.generalTextShow
  : globalStore.translations.generalTextHide
));
</script>

<template>
  <ValidationInput
    class="password-input"
    :validation-rules="passwordValidation"
    :label-text="props.labelText"
    :floating-label="toggleFloatingLabelName"
    floating-label-state
    :type="state.passwordFieldType"
    :validate-on-key-up="props.validateOnKeyUp"
    autocomplete="off"
    maxlength="50"
    required
    :help-text="props.helpText"
    @show-password="handleShowPassword"
  />
</template>
