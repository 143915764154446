<script setup>
/* Imports */
import { reactive, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import useGlobalStore from '../../store/globalStore';
import useMfaStore from '../../store/mfaStore';
import useViewStore from '../../store/viewStore';
import useVerifyAccountStore from '../../store/verifyAccountStore';
import useFormValidationStore from '../../store/formValidationStore';

import PortalPrimaryHeader from '../../components/PortalPrimaryHeader/PortalPrimaryHeader.vue';
import ValidationInput from '../../components/ValidationInput/ValidationInput.vue';
import ValidationForm from '../../components/ValidationForm/ValidationForm.vue';

import redirectToServiceUrl from '../../modules/redirectToServiceUrl';
import { buildTemplate, getCurrentRoute } from '../../modules/helperFunctions';
import redirectWithSAML from '../../modules/postSAMLResponse';

/* Store */
const globalStore = useGlobalStore();
const mfaStore = useMfaStore();
const viewStore = useViewStore();
const verifyAccountStore = useVerifyAccountStore();
const formValidationStore = useFormValidationStore();

/* Route */
const router = useRouter();
const currentRoute = getCurrentRoute(router);

/* State */
const state = reactive({
  verificationCode: null,
  validation: {},
  formLoading: false,
  userPhoneNumber: computed(() => verifyAccountStore.getVerifyPhoneNumber),
  requestCodeDisabled: false,
});

const verificationMessage = computed(() => buildTemplate(
  globalStore.translations.createAccVerificationCodeMessage,
  [state.userPhoneNumber],
));
const verificationCodeValidation = [formValidationStore.getIsRequired];

const handleSubmit = async () => {
  state.formLoading = true;
  const response = await verifyAccountStore.verifyPhoneCode({
    verificationCode: state.verificationCode,
  });

  const {
    authType,
    serviceURL,
    serviceTicketId,
    responseStatus,
    samlResponse,
  } = response;
  const { type } = responseStatus;

  // Session timeout/expired, route to Sign-In
  if (type === 'REQUEST_FAIL' || type === 'SESSION_EXPIRED') {
    if (currentRoute.name === 'SignIn') {
      viewStore.setCurrentView('signIn');
    } else {
      router.push({ name: 'SignIn' });
    }

    return;
  }

  /* Redirect to MFA if required */
  if (mfaStore.getIsMfaFlow) {
    router.push({ name: 'Mfa' });

    return;
  }

  if (type === 'RECONSENT_REQUIRED') {
    viewStore.setCurrentView('reConsentForm');

    return;
  }

  // Success
  if (type === 'SUCCESSFUL') {
    if (authType === 'SAML') {
      redirectWithSAML(serviceURL, samlResponse);
      return;
    }
    redirectToServiceUrl(serviceURL, serviceTicketId);
    return;
  }
  state.formLoading = false;
};

const requestNewCode = async () => {
  // don't make a new code request if request is disabled
  if (state.requestCodeDisabled) return;

  state.requestCodeDisabled = true;
  const response = await verifyAccountStore.requestVerificationCode();

  const { responseStatus } = response;
  const { type } = responseStatus;

  // Session lost/expired, route back to login
  if (type === 'REQUEST_FAIL' || type === 'SESSION_EXPIRED') {
    if (currentRoute.name === 'SignIn') {
      viewStore.setCurrentView('signIn');
    } else {
      router.push({ name: 'SignIn' });
    }

    return;
  }

  // No phone number, route to update phone
  if (type === 'PHONE_REGISTRATION_REQUIRED') {
    viewStore.setCurrentView('updatePhone');

    return;
  }

  // New verification code sent
  if (type === 'PHONE_VERIFICATION_NEW_CODE') {
    setTimeout(() => {
      // clear flash messages so new code message shows
      globalStore.resetFlashMessages();

      state.requestCodeDisabled = false;
    }, 30000);
  } else {
    state.requestCodeDisabled = false;
  }
};

const handleUpdatePhone = () => {
  viewStore.setCurrentView('updatePhone');
};

/* Form validation */
const validateInput = (name, invalid) => {
  state.validation[name] = invalid;
};

onMounted(async () => {
  const response = await verifyAccountStore.verifyAccount();

  const {
    phoneNumber,
    responseStatus,
  } = response;
  const { type } = responseStatus;

  // Session timeout, route to Sign-In
  if (type === 'REQUEST_FAIL' || type === 'SESSION_EXPIRED') {
    if (currentRoute.name === 'SignIn') {
      viewStore.setCurrentView('signIn');
    } else {
      router.push({ name: 'SignIn' });
    }
    return;
  }

  // No phone number, route to update phone
  if (type === 'CN_VERIFICATION_PHONE_NUMBER_NEEDED') {
    viewStore.setCurrentView('updatePhone');

    return;
  }

  // Success, display user's phone number
  if (type === 'SUCCESSFUL') {
    verifyAccountStore.setVerifyPhoneNumber(phoneNumber);
  }
});
</script>
<template>
  <div class="verify-phone">
    <Portal-Primary-Header :title="globalStore.translations.mobileVerifyAccountPageTitle" />
    <g-copy
      type="large"
    >
      {{ verificationMessage }}
    </g-copy>
    <Validation-Form
      class="verify-phone__form"
      :submit-form="handleSubmit"
      :submit-btn-text="globalStore.translations.generalButtonSignIn"
      :validation="state.validation"
      :loading="state.formLoading"
    >
      <fieldset class="verify-phone__form__input code">
        <Validation-Input
          id="verificationCode"
          v-model="state.verificationCode"
          name="verificationCode"
          class="verify-phone__form__input"
          :validation-rules="verificationCodeValidation"
          :label-text="globalStore.translations.generalTextVerificationCode"
          type="number"
          required
          @validate-input="validateInput"
        />
      </fieldset>
    </Validation-Form>
    <div class="verify-phone__form__actions">
      <button
        type="button"
        class="verify-phone__form__link-btn"
        :disabled="state.requestCodeDisabled"
        @click="requestNewCode"
      >
        {{ globalStore.translations.codeEntryRequestNewCode }}
      </button>
      <button
        type="button"
        data-testid="update-phone-number"
        class="verify-phone__form__link-btn"
        @click="handleUpdatePhone"
      >
        {{ globalStore.translations.verifyAccountSetPhonePageTitle }}
      </button>
    </div>
  </div>
</template>

<style lang="scss">
.verify-phone__form {
  &__helper__text {
    margin-top: 0;
    margin-bottom: 3rem;
    font-size: 0.8em;
  }

  &__input {
    &.code {
      .validation-form__input {
        margin-bottom: 0.5em;
      }
    }

    &.checkbox {
      margin-bottom: 0.5em;
    }
  }

  &__links {
    overflow: hidden;

    &.terms-container {
      margin-bottom: 1em;
      padding-left: 1.85em;

      p {
        margin-bottom: 1rem;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
  }

  &__link-btn {
    float: left;
    clear: both;
    background: transparent;
    border: 0;
    color: var(--sso-text-link-textColor);
    cursor: pointer;
    font-family: var(--sso-text-body-fontStack);
    font-size: var(--sso-text-body-fontSize);
    font-weight: 400;
    text-decoration: underline;
    padding: 0;
    margin-bottom: 1em;
    text-transform: capitalize;

    &:disabled {
      color: $color-gray-45;
      cursor: not-allowed;
    }
  }
}
</style>
