import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import App from './containers/App/App.vue';
import router from './router';
/* eslint-disable-next-line no-unused-vars */
import * as registerComponents from './modules/registerComponents';

// set up pinia
const pinia = createPinia();
// make router available in the store with this.router
pinia.use(({ store }) => {
  // eslint-disable-next-line no-param-reassign
  store.router = markRaw(router);
});

const app = createApp(App);
app.use(router);
app.use(pinia);

app.mount('#app');
