const directory = 'portal';
const subdirectory = 'sso';
const apiDirectory = 'api';

export default {
  directory,
  subdirectory,
  baseUrl: `/${directory}/${subdirectory}/:locale`,
  apiUrl: `/${directory}/${apiDirectory}`,
};
