<script setup>
/* Imports */
import { computed, reactive } from 'vue';
import useGlobalStore from '../../store/globalStore';
import { buildOrConcatTemplate } from '../../modules/helperFunctions';

/* Store */
const globalStore = useGlobalStore();

/* State */
const state = reactive({
  termsOfUseUrl: globalStore.getTermOfUseUrl,
  privacyStatementUrl: globalStore.getPrivacyUrl,
  securityUrl: globalStore.getSecurityUrl,
  locale: globalStore.getLocale,
});

const footerCopyRight = computed(() => buildOrConcatTemplate(
  globalStore.translations.footerCopyRight,
  [new Date().getFullYear()],
  '{0}',
));
</script>

<template>
  <div class="portal-footer-labels">
    <div
      class="gf__legal gf__legal__only"
      :class="state.locale"
    >
      <div class="gf__legal__container">
        <p
          id="copyRight"
          class="gf__legal__container__copyright"
        >
          {{ footerCopyRight }}
        </p>
      </div>
      <ul class="gfl__list">
        <li
          class="gfl__list__item js__gfl__list__item"
          aria-hidden="false"
        >
          <span
            class="js__gfl__list__item__link gfl__list__item__link__wrapper"
          >
            <a
              id="termsOfUseUrl"
              class="gfl__list__item__link"
              :href="state.termsOfUseUrl"
            >
              {{ globalStore.translations.footerTermsOfUse }}
            </a>
          </span>
        </li>

        <li
          class="gfl__list__item js__gfl__list__item"
          aria-hidden="false"
        >
          <span
            class="js__gfl__list__item__link gfl__list__item__link__wrapper"
          >
            <a
              id="privacyStatementUrl"
              class="gfl__list__item__link"
              :href="state.privacyStatementUrl"
            >
              {{ globalStore.translations.footerPrivacy }}
            </a>
          </span>
        </li>

        <li
          class="gfl__list__item js__gfl__list__item"
          aria-hidden="false"
        >
          <span
            class="js__gfl__list__item__link gfl__list__item__link__wrapper"
          >
            <a
              id="security_url"
              class="gfl__list__item__link"
              :href="state.securityUrl"
            >
              {{ globalStore.translations.footerSecurity }}
            </a>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.portal-footer-labels {
  display: flex;
  width: 100%;

  /* stylelint-disable max-line-length */

  .gf__legal {
    flex: 1 1 100%;
    background: $color-black;
    color: $color-white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.75rem 1.5rem;
    font-family: 'Roboto', '"Noto Sans TC"', '"Noto Sans SC"', '"Noto Sans JP"', '"Noto Sans KR"', 'Prompt', sans-serif, '"Microsoft JhengHei"';

    @include breakpoint('lg') {
      flex-direction: row;
    }

    p {
      font-size: 0.8rem;
      text-align: center;
      color: $color-white;
      font-weight: 500;
    }

    &.zh-TW {
      font-family: 'Roboto', '"Noto Sans TC"', sans-serif;
    }

    &.zh-CN {
      font-family: 'Roboto', '"Noto Sans SC"', sans-serif;
    }

    &.ja-JP {
      font-family: 'Roboto', '"Noto Sans JP"', sans-serif;
    }

    &.ko-KR {
      font-family: 'Roboto', '"Noto Sans KR"', sans-serif;
    }

    &.th-TH {
      font-family: 'Roboto', 'Prompt', sans-serif;
    }

    &.vi-VN {
      font-family: 'Roboto', sans-serif;
    }

    &__only {
      .gfl__list {
        margin: 0 0 0.75rem 0;

        @include breakpoint('lg') {
          margin: 0;
        }
      }
    }

    &__container {
      &__china {
        align-items: start;
        display: flex;

        img {
          padding-right: 0.2rem;
        }
      }

      .legal-copy {
        color: inherit;
        text-decoration: none;

        a {
          color: inherit;
          text-decoration: none;
        }

        p {
          margin-top: 0;
        }
      }
    }
  }
  /* stylelint-enable max-line-length */

  /* stylelint-disable max-line-length */

  .gfl__list {
    font-family: 'Roboto', '"Noto Sans TC"', '"Noto Sans SC"', '"Noto Sans JP"', '"Noto Sans KR"', 'Prompt', sans-serif, '"Microsoft JhengHei"';
    font-size: 0.8rem;
    margin: 1rem 0;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0;

    &__item {
      list-style: none;
      margin: 0 1rem;
      font-weight: 500;

      &__link {
        &:active,
        &:focus,
        &:hover,
        &:link,
        &:visited {
          color: $color-white;
          text-decoration: none;
        }

        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }

      &:last-child {
        margin-right: 1rem;

        @include breakpoint('lg') {
          margin-right: 0;
        }
      }
    }

    &.zh-TW {
      font-family: 'Roboto', '"Noto Sans TC"', sans-serif;
    }

    &.zh-CN {
      align-items: baseline;
      font-family: 'Roboto', '"Noto Sans SC"', sans-serif;
    }

    &.ja-JP {
      font-family: 'Roboto', '"Noto Sans JP"', sans-serif;
    }

    &.ko-KR {
      font-family: 'Roboto', '"Noto Sans KR"', sans-serif;
    }

    &.th-TH {
      font-family: 'Roboto', 'Prompt', sans-serif;
    }

    &.vi-VN {
      font-family: 'Roboto', sans-serif;
    }
  }
  /* stylelint-enable max-line-length */
}

.gfl__locale__link {
  font-size: 0.8rem;
  margin: 0.5rem 0;
  width: 100%;
  text-align: center;

  &:active,
  &:focus,
  &:hover,
  &:link,
  &:visited {
    color: $color-white;
    text-decoration: none;
  }

  .gf__legal p {
    @include breakpoint('lg') {
      text-align: left;
    }
  }

  &.zh-TW {
    font-family: 'Roboto', '"Noto Sans TC"', sans-serif;
  }

  &.zh-CN {
    font-family: 'Roboto', '"Noto Sans SC"', sans-serif;
  }

  &.ja-JP {
    font-family: 'Roboto', '"Noto Sans JP"', sans-serif;
  }

  &.ko-KR {
    font-family: 'Roboto', '"Noto Sans KR"', sans-serif;
  }

  &.th-TH {
    font-family: 'Roboto', 'Prompt', sans-serif;
  }

  &.vi-VN {
    font-family: 'Roboto', sans-serif;
  }
}
</style>
