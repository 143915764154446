<script setup>
/* Imports */
import { onMounted } from 'vue';
import useGlobalStore from '../../store/globalStore';
import useViewStore from '../../store/viewStore';
import useSigninStore from '../../store/signinStore';

import SignInForm from '../../containers/SignInForm/SignInForm.vue';
import ForgotPassword from '../../containers/ForgotPassword/ForgotPassword.vue';
import ChangePassword from '../../containers/ChangePassword/ChangePassword.vue';
import VerifyPhone from '../../containers/VerifyPhone/VerifyPhone.vue';
import UpdatePhone from '../../containers/UpdatePhone/UpdatePhone.vue';
import ReConsentForm from '../../containers/ReConsentForm/ReConsentForm.vue';

/* Store */
const globalStore = useGlobalStore();
const viewStore = useViewStore();
const signInStore = useSigninStore();

const setDefaultView = () => {
  globalStore.setGlobalFlow('signIn');
  viewStore.setCurrentView('signIn');
};

onMounted(() => signInStore.checkLogin(setDefaultView));
</script>

<template>
  <Sign-In-Form v-if="viewStore.getShowSignIn" />
  <Forgot-Password v-if="viewStore.getShowForgotPassword" />
  <Change-Password v-if="viewStore.getShowChangePassword" />
  <Verify-Phone v-if="viewStore.getShowVerifyPhone" />
  <Update-Phone v-if="viewStore.getShowUpdatePhone" />
  <ReConsent-Form v-if="viewStore.getReConsentForm" />
</template>
