import { defineStore } from 'pinia';
import axios from 'axios';
import useGlobalStore from './globalStore';
import useMfaStore from './mfaStore';
import baseUrls from '../router/config/baseUrls';

const { apiUrl } = baseUrls;

const defaultLegalAge = 16;

export default defineStore('createAccountStore', {
  state: () => ({
    legalAge: defaultLegalAge,
    countryAgeMapping: undefined,
  }),
  getters: {
    getLegalAge: (state) => state.legalAge,
    getCountryAgeMapping: (state) => state.countryAgeMapping,
  },
  actions: {
    async setCaptchaAuditLog(payload) {
      const { setRequest } = useGlobalStore();
      await setRequest(
        {
          method: 'POST',
          url: `${apiUrl}/auditLog/external`,
          payload,
        },
      );
    },
    async setCreateAccount(payload) {
      const { setRequest } = useGlobalStore();
      const customAlerts = {
        INVALID_PASSWORD: 'warning',
        INVALID_EMAIL: 'warning',
        PENDING_DELETE: 'warning',
        EMAIL_TAKEN: 'warning',
      };

      const response = await setRequest(
        {
          method: 'POST',
          url: `${apiUrl}/createNewAccount`,
          payload,
        },
        customAlerts,
      );

      const { responseStatus, customerMfaInfo } = response;
      const { type } = responseStatus;

      // set or clear MFA data
      const { setInitMfaData } = useMfaStore();
      setInitMfaData({ type, customerMfaInfo });

      return response;
    },
    async setPromoteAccount() {
      const { setRequest } = useGlobalStore();

      const response = await setRequest(
        {
          method: 'POST',
          url: `${apiUrl}/createNewAccount/promoteAccount`,
        },
      );

      const { responseStatus, customerMfaInfo } = response;
      const { type } = responseStatus;

      // set or clear MFA data
      const { setInitMfaData } = useMfaStore();
      setInitMfaData({ type, customerMfaInfo });

      return response;
    },
    setCountryAgeMapping(payload) {
      this.countryAgeMapping = payload;
    },
    async setLegalAge(payload) {
      try {
        let legalAge;
        let legalAges;
        if (!this.getCountryAgeMapping) {
          const response = await axios.get('https://static.garmin.com/com.garmin.sso/createAccount/countryAgeMapping.json');
          legalAges = response.data;
          this.setCountryAgeMapping(legalAges);
        } else {
          legalAges = this.getCountryAgeMapping;
        }

        if (legalAges === undefined || legalAges.length < 1) {
          legalAge = defaultLegalAge;
        } else if (legalAges[payload]) {
          legalAge = legalAges[payload];
        } else {
          legalAge = legalAges.DEFAULT;
        }

        this.legalAge = legalAge;
      } catch (err) {
        console.error('Error calling countryAgeMapping.json');
      }
    },
  },
});
