<script setup>
/* imports */
import { reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import useGlobalStore from '../../store/globalStore';
import useMfaStore from '../../store/mfaStore';
import useViewStore from '../../store/viewStore';

import PortalPrimaryHeader from '../../components/PortalPrimaryHeader/PortalPrimaryHeader.vue';
import RadioInput from '../../components/RadioInput/RadioInput.vue';
import ValidationForm from '../../components/ValidationForm/ValidationForm.vue';
import PortalDisclaimer from '../PortalDisclaimer/PortalDisclaimer.vue';

/* Store */
const globalStore = useGlobalStore();
const mfaStore = useMfaStore();
const viewStore = useViewStore();

/* Route */
const router = useRouter();

/* Props */
const props = defineProps({
  pageHeader: {
    type: String,
    default: '',
  },
});

/* State */
const state = reactive({
  selectedValue: mfaStore.getMfaOption || 'email',
  phoneOption: computed(() => mfaStore.getMfaPhone),
  emailOption: computed(() => mfaStore.getMfaEmail),
  formLoading: false,
  updatePhoneCopy: computed(() => (mfaStore.getHasMfaPhone
    ? globalStore.translations.mfaSelectionDifferentPhone
    : globalStore.translations.mfaSelectionAddPhone)),
});

/* Submit the form */
const handleSubmit = async () => {
  // first set option value in store
  mfaStore.setMfaOption(state.selectedValue);

  state.formLoading = true;
  const response = await mfaStore.updateMfaInfo({
    // then grab option value from store to pass to endpoint
    mfaMethodToBeUsed: mfaStore.getMfaOption,
  });
  state.formLoading = false;

  const {
    responseStatus,
  } = response;
  const { type } = responseStatus;

  // Session Expired
  if (type === 'SESSION_EXPIRED') {
    router.push({ name: 'SignIn' });
  }

  if (type === 'MFA_CODE_SENT') {
    viewStore.setCurrentView('mfaEnterCode');
  }
};

/* Cancel the form */
const cancelBtn = () => {
  if (mfaStore.getSetupMfa) {
    viewStore.setCurrentView('mfaRequired');
  } else {
    viewStore.setCurrentView('mfaEnterCode');
  }
};

/* Handle the option */
const handleSelection = (newValue) => {
  state.selectedValue = newValue;
};

/* Update phone number */
const updatePhone = () => {
  viewStore.setCurrentView('mfaAddPhoneNumber');
};
</script>

<template>
  <div class="mfa-options">
    <Portal-Primary-Header
      :title="props.pageHeader"
    />
    <g-copy
      type="large"
    >
      {{ globalStore.translations.mfaSelectionHeading }}
    </g-copy>
    <Validation-Form
      class="mfa-options__form"
      :submit-form="handleSubmit"
      :submit-btn-text="globalStore.translations.generalButtonNext"
      :cancel-btn-text="globalStore.translations.generalButtonGoBack"
      :cancel-btn="cancelBtn"
      :loading="state.formLoading"
    >
      <fieldset
        v-if="!mfaStore.getPermMFAEmailSetup"
        class="mfa-options__form__radio__margin"
      >
        <g-copy
          class="mfa-options__form__mfacode-selection"
          type="large"
        >
          {{ globalStore.translations.mfaSelectionContent }}
        </g-copy>

        <Radio-Input
          v-if="mfaStore.getHasMfaEmail"
          id="mfaOptionsEmail"
          :label-title="globalStore.translations.generalTextEmailOrUsername"
          :label-value="state.emailOption"
          :checked="mfaStore.getMfaOptionIsEmail"
          name="mfaOptionsInput"
          value="email"
          @change="handleSelection"
        />
        <Radio-Input
          v-if="mfaStore.getHasMfaPhone"
          id="mfaOptionsPhone"
          :label-title="globalStore.translations.mfaTextMessage"
          :label-value="state.phoneOption"
          :checked="mfaStore.getMfaOptionIsPhone"
          name="mfaOptionsInput"
          value="phone"
          @change="handleSelection"
        />
        <button
          v-if="mfaStore.getSetupMfa"
          id="update-phone-button"
          class="mfa-options__form__link-btn"
          type="button"
          data-testid="update-phone-number"
          @click="updatePhone"
        >
          {{ state.updatePhoneCopy }}
        </button>
      </fieldset>

      <fieldset
        v-else
        class="mfa-options__form__radio"
      >
        <g-copy
          class="mfa-options__form__mfacode-selection"
          type="large"
        >
          {{ globalStore.translations.mfaSelectionContentPermMfa }}
        </g-copy>
        <Radio-Input
          v-if="mfaStore.getHasMfaEmail"
          id="mfaOptionsEmail"
          :label-title="globalStore.translations.generalTextEmailOrUsername"
          :label-value="state.emailOption"
          :checked="mfaStore.getMfaOptionIsEmail"
          name="mfaOptionsInput"
          value="email"
          @change="handleSelection"
        />
      </fieldset>

      <Portal-Copy-Con v-if="mfaStore.getHasMfaPhone && !mfaStore.getPermMFAEmailSetup">
        <portal-disclaimer />
      </Portal-Copy-Con>
    </Validation-Form>
  </div>
</template>

<style lang="scss">
.mfa-options__form {
  &__radio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__margin {
      margin-bottom: 3em;
    }
  }

  &__link-btn {
    background: transparent;
    border: 0;
    color: var(--sso-text-link-textColor);
    cursor: pointer;
    font-family: var(--sso-text-body-fontStack);
    font-size: var(--sso-text-body-fontSize);
    font-weight: 400;
    text-decoration: underline;
    padding: 0;
  }

  &__mfacode-selection {
    margin: 0 0 1.75em;
  }
}
</style>
