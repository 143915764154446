const flattenObject = (objToFlatten) => {
  const getEntries = (o, prefix = '') => Object.entries(o).flatMap(
    ([k, v]) => (Object(v) === v ? getEntries(v, `${prefix}${k}_`) : [[`${prefix}${k}`, v]]),
  );
  return Object.fromEntries(getEntries(objToFlatten));
};

const buildTemplate = (template, values) => (
  values.reduce((newString, value, i) => (
    newString.replace(`{${i}}`, value)
  ), template)
);

const buildOrConcatTemplate = (template, values, key) => {
  if (template.includes(key)) {
    return buildTemplate(template, values);
  }

  return `${template} ${values}`;
};

const getQueryParamValue = (queryName) => {
  const urlParams = new URLSearchParams(window.location.search);
  const decodeQueryParam = (param) => param && decodeURIComponent(param.replace(/\+/g, ' '));

  return decodeQueryParam(urlParams.get(queryName));
};

const getCurrentRoute = (router) => router?.currentRoute?.value;

export {
  flattenObject,
  buildTemplate,
  buildOrConcatTemplate,
  getQueryParamValue,
  getCurrentRoute,
};
