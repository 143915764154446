<script setup>
/* Props */
const props = defineProps({
  type: {
    type: String,
    default: 'default',
  },
});
</script>
<template>
  <g-loader
    class="portal-loader"
    :type="props.type"
  />
</template>

<style lang="scss">
  .portal-loader {
    width: 3.8em;
    margin: 0 auto;
  }
</style>
