<script setup>
/* Imports */
import { computed, onMounted, ref } from 'vue';
import PortalButton from '../PortalButton/PortalButton.vue';

/* Props */
const props = defineProps({
  submitForm: {
    type: Function,
    default: () => null,
  },
  submitBtnText: {
    type: String,
    default: 'Submit',
  },
  cancelBtnText: {
    type: String,
    default: '',
  },
  cancelBtn: {
    type: Function,
    default: () => null,
  },
  validation: {
    type: Object,
    default() {
      return null;
    },
  },
  loading: {
    type: Boolean,
    default: false,
  },
  allowAutofill: {
    type: Boolean,
    default: true,
  },
});

/* Handle form validation */
const hasCancelBtn = !!props.cancelBtnText.length;
const formRef = ref(null);
let requiredFieldCount = null;

const validateForm = (form) => form.some((valid) => valid !== false);

const fieldCount = () => [...formRef.value.elements].filter((element) => element.required).length;

const isDisabled = computed(() => {
  if (formRef.value) {
    requiredFieldCount = fieldCount();

    const additionalRequiredFields = formRef.value.querySelectorAll('.additional-required');
    const totalAdditionalRequiredFields = Array.from(additionalRequiredFields).length;

    // Add any additional required fields (like captcha) to required field count
    requiredFieldCount += totalAdditionalRequiredFields;
  }
  // Disable button if it's loading
  if (props.loading) return true;

  /* Enable submit button if no validation prop is passed  */
  if (!props.validation) return false;

  const validationArray = Object.values(props.validation);

  if (requiredFieldCount !== validationArray.length) return true;

  return validateForm(validationArray);
});

onMounted(() => {
  requiredFieldCount = fieldCount();
});

/* Handle submit the form */
const handleSubmit = (e) => {
  e.preventDefault();

  const inValidList = [...e.target.form.elements].reduce((list, el) => {
    if (el.tagName === 'INPUT') {
      /* Need to focus then blur each input to trigger validation */
      el.focus();
      el.blur();

      return [...list, el.getAttribute('inValid')];
    }

    return list;
  }, []);

  const formIsValid = !inValidList.includes('true');

  if (formIsValid) props.submitForm();
};

/* Handle cancel the form */
const handleCancelBtn = (e) => {
  e.preventDefault();
  props.cancelBtn();
};
</script>

<template>
  <form
    ref="formRef"
    class="validation-form"
    :autocomplete="props.allowAutofill"
  >
    <section class="validation-form-fields">
      <slot />
    </section>
    <section class="validation-form-buttons">
      <Portal-Button
        data-testid="submit-btn"
        theme="ocean-blue"
        size="large"
        type="submit"
        :disabled="isDisabled"
        :loading="props.loading"
        @click="handleSubmit"
      >
        {{ props.submitBtnText }}
      </Portal-Button>
      <Portal-Button
        v-if="hasCancelBtn"
        size="large"
        flat
        data-testid="cancel-btn"
        @click="handleCancelBtn"
      >
        {{ props.cancelBtnText }}
      </Portal-Button>
    </section>
  </form>
</template>

<style lang="scss">
.validation-form {
  margin: 2.5em 0 1.75em;

  &__input {
    margin-bottom: 1.5em;
  }

  &-fields {
    margin-bottom: 1.5em;
  }
}
</style>
