<script setup>
/* Imports */
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import useMfaStore from '../../store/mfaStore';
import useGlobalStore from '../../store/globalStore';
import useViewStore from '../../store/viewStore';

import MfaRequired from '../../containers/MfaRequired/MfaRequired.vue';
import MfaEnabled from '../../containers/MfaEnabled/MfaEnabled.vue';
import MfaEnterCode from '../../containers/MfaEnterCode/MfaEnterCode.vue';
import MfaOptions from '../../containers/MfaOptions/MfaOptions.vue';
import MfaAddPhoneNumber from '../../containers/MfaAddPhoneNumber/MfaAddPhoneNumber.vue';

/* Router */
const router = useRouter();

/* Store */
const mfaStore = useMfaStore();
const globalStore = useGlobalStore();
const viewStore = useViewStore();

const setDefaultView = () => {
  if (mfaStore.getSetupMfa) {
    viewStore.setCurrentView('mfaRequired');
  } else {
    viewStore.setCurrentView('mfaEnterCode');
  }
};

const setupMfa = async () => {
  // if customer info isn't set, perform an MFA check to see if they've already been logged in
  if (!mfaStore.getCustomerMfaInfo) {
    globalStore.setPortalLoading(true);
    const response = await mfaStore.performMfaCheck();
    globalStore.setPortalLoading(false);

    const { type } = response.responseStatus;

    if (type === 'LOGIN_REQUIRED') {
      router.push({ name: 'SignIn' });

      // exit here because we don't want to set an MFA view using setDefaultView since we're redirecting to the sign in page
      return;
    }
    // if the clientId is invalid to not show red message or to not redirect to an
    // empty enter mfa code page where any step is invalid because parameters are not set.
    if (type === 'INVALID_REQUEST') {
      globalStore.resetFlashMessages();
      router.push({ name: 'NotFound' });

      return;
    }
  }

  setDefaultView();
};

onMounted(() => setupMfa());
</script>

<template>
  <Mfa-Required
    v-if="viewStore.getShowMfaRequired"
    :page-header="globalStore.getPageHeader"
  />
  <Mfa-Enter-Code
    v-if="viewStore.getShowMfaEnterCode"
    :page-header="globalStore.getPageHeader"
  />
  <Mfa-Options
    v-if="viewStore.getShowMfaOptions"
    :page-header="globalStore.getPageHeader"
  />
  <Mfa-Enabled
    v-if="viewStore.getShowMfaEnabled"
    :page-header="globalStore.getPageHeader"
  />
  <Mfa-Add-Phone-Number
    v-if="viewStore.getShowMfaAddPhoneNumber"
    :page-header="globalStore.getPageHeader"
  />
</template>
