import { defineStore } from 'pinia';
import clientRegistryTemp from '../config/clientRegistryTemp';

export default defineStore('clientRegistriesStore', {
  state: () => ({
    clientRegistries: {},
  }),
  getters: {
    getClientRegistries: (state) => state.clientRegistries,
    getClientId: (state) => state.clientRegistries?.clientId,
    getRegistryOptions: (state) => state.clientRegistries?.registryOption,
    getLockToEmailAddress() {
      return this.getRegistryOptions?.lockToEmailAddress;
    },
    getContinueAsGuest() {
      return this.getRegistryOptions?.continueAsGuest;
    },
    getShowConnectLegalAge() {
      return this.getRegistryOptions?.showConnectLegalAge;
    },
    getLocationPromptShown() {
      return this.getRegistryOptions?.locationPromptShown;
    },
    getGlobalOptInShown() {
      return this.getRegistryOptions?.globalOptInShown;
    },
    getTermsOfUseUrl() {
      return this.getRegistryOptions?.termsOfUseUrl;
    },
    getPrivacyStatementUrl() {
      return this.getRegistryOptions?.privacyStatementUrl;
    },
    getCreateAccountShown() {
      return this.getRegistryOptions?.createAccountShown;
    },
    getRegistryVisualOptions: (state) => state.clientRegistries?.registryVisualOptions,
  },
  actions: {
    setClientRegistriesData() {
      const clientRegistriesFallback = import.meta.env.DEV ? clientRegistryTemp : {};
      const formattedClientRegistries = window?.GarminAppBootstrap?.clientRegistries
        ? window?.GarminAppBootstrap?.clientRegistries : clientRegistriesFallback;
      /* eslint-disable-next-line prefer-destructuring */
      this.clientRegistries = formattedClientRegistries;
    },
  },
});
