<script setup>
/* Imports */
import { computed, reactive } from 'vue';
import { useRouter } from 'vue-router';
import useGlobalStore from '../../store/globalStore';
import useMfaStore from '../../store/mfaStore';
import useFormValidationStore from '../../store/formValidationStore';
import useViewStore from '../../store/viewStore';
import useConsentStore from '../../store/consentStore';

import PortalPrimaryHeader from '../../components/PortalPrimaryHeader/PortalPrimaryHeader.vue';
import PortalSecondaryHeader from '../../components/PortalSecondaryHeader/PortalSecondaryHeader.vue';
import ValidationInput from '../../components/ValidationInput/ValidationInput.vue';
import ValidationForm from '../../components/ValidationForm/ValidationForm.vue';

import redirectToServiceUrl from '../../modules/redirectToServiceUrl';
import { buildOrConcatTemplate } from '../../modules/helperFunctions';
import redirectWithSAML from '../../modules/postSAMLResponse';

/* Router */
const router = useRouter();

/* Store */
const globalStore = useGlobalStore();
const mfaStore = useMfaStore();
const formValidationStore = useFormValidationStore();
const viewStore = useViewStore();
const consentStore = useConsentStore();

/* Locale */
const locale = globalStore.getLocale;

/* Helper */
const helpLink = `https://support.garmin.com/${locale}/?faq=uGHS8ZqOIhA0usBzBMdJu7`;

/* Props */
const props = defineProps({
  pageHeader: {
    type: String,
    default: '',
  },
});

/* State */
const state = reactive({
  securityCode: null,
  rememberMyBrowserInput: false,
  validation: {},
  formLoading: false,
  mfaOptionVal: computed(() => mfaStore.getMfaOptionValue),
  requestCodeDisabled: false,
  consents: {},
  reconsentList: computed(() => {
    if (consentStore.getReconsentTypeList !== null) {
      return consentStore.getReconsentTypeList;
    }
    return [];
  }),
});

const verificationMessage = computed(() => buildOrConcatTemplate(
  globalStore.translations.codeEntrySentCode,
  [state.mfaOptionVal],
  '{0}',
));

const securityCodeValidation = [formValidationStore.getIsMfaCodeValid];

const handleSubmit = async () => {
  state.formLoading = true;
  const response = await mfaStore.verifyMfaCode({
    mfaMethod: mfaStore.getMfaOption,
    mfaVerificationCode: state.securityCode,
    rememberMyBrowser: state.rememberMyBrowserInput,
    reconsentList: state.reconsentList,
  });

  const {
    authType, serviceURL, serviceTicketId, responseStatus, samlResponse,
  } = response;
  const { type } = responseStatus;

  // Session Expired
  if (type === 'SESSION_EXPIRED') {
    router.push({ name: 'SignIn' });

    return;
  }

  if (type === 'MFA_SETUP_COMPLETED') {
    viewStore.setCurrentView('mfaEnabled');

    return;
  }

  if (type === 'SUCCESSFUL') {
    if (authType === 'SAML') {
      redirectWithSAML(serviceURL, samlResponse);
      return;
    }
    redirectToServiceUrl(serviceURL, serviceTicketId);
    return;
  }

  state.formLoading = false;
};

const cancelBtn = () => {
  if (mfaStore.getSetupMfa) {
    viewStore.setCurrentView('mfaOptions');
  } else {
    router.push({ name: 'SignIn' });
  }
};

const requestNewCode = async () => {
  // don't make a new code request if request is disabled
  if (state.requestCodeDisabled) return;

  state.requestCodeDisabled = true;
  const response = await mfaStore.requestMfaCode();

  const { responseStatus } = response;
  const { type } = responseStatus;

  // Session Expired
  if (type === 'SESSION_EXPIRED') {
    router.push({ name: 'SignIn' });

    return;
  }

  if (type === 'MFA_CODE_SENT') {
    setTimeout(() => {
      // clear flash messages so new code message
      globalStore.resetFlashMessages();

      state.requestCodeDisabled = false;
    }, 30000);
  } else {
    state.requestCodeDisabled = false;
  }
};

const clickDiffOption = (e) => {
  e.preventDefault();

  viewStore.setCurrentView('mfaOptions');
};

const validateInput = (name, invalid) => {
  state.validation[name] = invalid;
};

/* checkbox validation */
const validateCheckbox = (event) => {
  console.log('checkbox event', event.target.name);
  state.validation[event.target.name] = !event.target.checked;
};

console.log('reconsentList', state.reconsentList);
</script>
<template>
  <div class="mfa-enter-code">
    <Portal-Primary-Header :title="props.pageHeader" />
    <Portal-Secondary-Header
      :title="globalStore.translations.codeEntryHeading"
    />
    <g-copy
      type="large"
    >
      {{ verificationMessage }}
    </g-copy>
    <Validation-Form
      class="mfa-enter-code__form"
      :submit-form="handleSubmit"
      :submit-btn-text="globalStore.translations.generalButtonNext"
      :cancel-btn-text="globalStore.translations.generalButtonGoBack"
      :cancel-btn="cancelBtn"
      :validation="state.validation"
      :loading="state.formLoading"
    >
      <fieldset class="mfa-enter-code__form__input">
        <Validation-Input
          id="securityCode"
          v-model="state.securityCode"
          class="mfa-enter-code__form__input"
          :validation-rules="securityCodeValidation"
          :label-text="globalStore.translations.codeEntryCodeEntryLabel"
          name="securityCode"
          type="number"
          required
          @validate-input="validateInput"
        />
      </fieldset>
      <fieldset
        v-for="(reconsent, index) in state.reconsentList"
        :key="reconsent"
        class="mfa-enter-code__form__checkbox"
      >
        <g-checkbox :label="reconsent.labelText">
          <input
            :id="reconsent.consentType"
            v-model="state.consents[index]"
            :name="reconsent.consentType"
            type="checkbox"
            required
            @change="validateCheckbox($event)"
          >
        </g-checkbox>
        <p class="mfa-enter-code__form__consent-text">
          <a
            :href="reconsent.consentUrl"
            target="_blank"
            rel="noreferrer noopener"
          >
            {{ reconsent.consentText }}
          </a>
        </p>
      </fieldset>
      <fieldset class="mfa-enter-code__form__checkbox">
        <g-checkbox
          :label="globalStore.translations.generalTextRememberThisBrowser"
        >
          <input
            id="remember-my-browser"
            v-model="state.rememberMyBrowserInput"
            name="remember"
            type="checkbox"
          >
        </g-checkbox>
      </fieldset>
      <div class="mfa-enter-code__form__links">
        <button
          id="request_new_code"
          type="button"
          class="mfa-enter-code__form__link-btn"
          :disabled="state.requestCodeDisabled"
          @click="requestNewCode"
        >
          {{ globalStore.translations.codeEntryRequestNewCode }}
        </button>
        <a
          :href="helpLink"
          class="mfa-enter-code__form__link-btn"
        >
          {{ globalStore.translations.codeEntryHavingTrouble }}
        </a>
        <button
          v-if="mfaStore.getHasMfaPhone"
          id="diff-ops-button"
          type="button"
          class="mfa-enter-code__form__link-btn"
          @click="clickDiffOption"
        >
          {{ globalStore.translations.codeEntryVerifyWithDifferentMethod }}
        </button>
      </div>
    </Validation-Form>
  </div>
</template>

<style lang="scss">
.mfa-enter-code__form {
  &__consent-text {
    margin-left: 30px;
  }

  &__input {
    .validation-input {
      margin-bottom: 0.5em;
    }
  }

  &__checkbox {
    margin-bottom: 1em;
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2em;
  }

  &__link-btn {
    background: transparent;
    border: 0;
    color: var(--sso-text-link-textColor);
    cursor: pointer;
    font-family: var(--sso-text-body-fontStack);
    font-size: var(--sso-text-body-fontSize);
    font-weight: 400;
    text-decoration: underline;
    padding: 0;
    margin-bottom: 1em;

    &:disabled {
      color: $color-gray-45;
      cursor: not-allowed;
    }
  }
}
</style>
