<script setup>
/* Imports */
import PortalLoader from '../PortalLoader/PortalLoader.vue';

/* Props */
const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <g-button
    size="large"
    align="center"
    class="portal-button"
    v-bind="$attrs"
  >
    <span v-show="!props.loading">
      <slot />
    </span>
    <span v-show="props.loading">
      <Portal-Loader
        type="dark"
      />
    </span>
  </g-button>
</template>

<style lang="scss">
.portal-button {
  width: 100%;
  text-align: center;

  .g__button {
    // stylelint-disable-next-line declaration-no-important
    width: 100% !important;

    span {
      pointer-events: none;
    }
  }
}
</style>
