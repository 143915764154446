<script setup>
/* Imports */
import { onMounted } from 'vue';
import useGlobalStore from '../../store/globalStore';
import useViewStore from '../../store/viewStore';
import useSigninStore from '../../store/signinStore';

import CreateAccountForm from '../../containers/CreateAccountForm/CreateAccountForm.vue';
import VerifyPhone from '../../containers/VerifyPhone/VerifyPhone.vue';
import UpdatePhone from '../../containers/UpdatePhone/UpdatePhone.vue';
import SecurityCode from '../../containers/SecurityCode/SecurityCode.vue';

/* Store */
const globalStore = useGlobalStore();
const viewStore = useViewStore();
const signInStore = useSigninStore();

const setDefaultView = () => {
  globalStore.setGlobalFlow('createAccount');
  viewStore.setCurrentView('createAccount');
};

onMounted(() => signInStore.checkLogin(setDefaultView));
</script>

<template>
  <Create-Account-Form
    v-if="viewStore.getShowCreateAccount"
  />
  <Verify-Phone
    v-if="viewStore.getShowVerifyPhone"
  />
  <Update-Phone
    v-if="viewStore.getShowUpdatePhone"
  />
  <Security-Code
    v-if="viewStore.getShowSecurityCode"
  />
</template>
