<script setup>
/* imports */
import { onMounted } from 'vue';
import useLogoutStore from '../../store/logoutStore';

const logoutStore = useLogoutStore();
onMounted(() => {
  logoutStore.logout();
});
</script>
