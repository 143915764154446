<script>
/* https://vuejs.org/api/sfc-script-setup.html#usage-alongside-normal-script */
export default {
  inheritAttrs: false,
};
</script>

<script setup>
/**
 * Need to disable import/first otherwise it tries to merge code from inline <script> into the
 * <script setup> tag which should stay in the <script> tag.
 * eslint-disable import/first
 */

/* Props */
const props = defineProps({
  labelTitle: {
    type: String,
    default: '',
  },
  labelValue: {
    type: String,
    default: '',
  },
});

/* Emits */
const emit = defineEmits(['change']);

/* Handle labelTitle */
const showLabelTitle = !!props.labelTitle.length;

/* Handle @change event */
const handleOnChangeEvent = (e) => emit('change', e.target.value);
</script>

<template>
  <div class="validation-radio">
    <div
      v-if="showLabelTitle"
      class="validation-radio__label"
    >
      {{ props.labelTitle }}
    </div>
    <g-radio
      :label="props.labelValue"
    >
      <input
        v-bind="$attrs"
        type="radio"
        @change="handleOnChangeEvent"
      >
    </g-radio>
  </div>
</template>

<style lang="scss">
.validation-radio {
  margin-bottom: 1em;

  &__label {
    @extend %font-primary-weight-medium;
  }
}
</style>
