<script setup>
/* Imports */
import {
  computed, reactive, onMounted,
} from 'vue';
import isEmpty from 'lodash/isEmpty';
import GarminLogo from '../GarminLogo/GarminLogo.vue';

/* Props */
const props = defineProps({
  logoTitle: {
    type: String,
    default: 'Garmin',
  },
  service: {
    type: String,
    default: '',
  },
  customServiceLogoUrl: {
    type: String,
    default: '',
  },
});

/* State */
const state = reactive({
  customLogoUrl: props.customServiceLogoUrl,
  customLogoBackgroundImage: getComputedStyle(document.documentElement).getPropertyValue('--sso-global-header-logoImageUrl'),
  displayCustomLogo: computed(() => !isEmpty(state.customLogoBackgroundImage)),
});

onMounted(() => {
  /**
   * We need to get and set the background image with JS instead of using the CSS custom property
   * directly in SCSS. This is related to an issue with using a CSS custom property string for
   * the background image URL.
   */
  if (state.displayCustomLogo) {
    const customLogoBgUrl = `url(${state.customLogoBackgroundImage})`;
    document.getElementById('custom-logo').style.backgroundImage = customLogoBgUrl;
  }
});
</script>

<template>
  <nav class="nav-header">
    <a
      v-if="state.displayCustomLogo"
      id="custom-logo"
      :href="state.customLogoUrl"
      :title="props.logoTitle"
      class="nav-header__custom-logo"
    />
    <a
      v-else
      href="https://www.garmin.com"
      title="Garmin Home"
      class="nav-header__logo"
    >
      <Garmin-Logo />
    </a>
  </nav>
</template>

<style lang="scss">
.nav-header {
  padding: 1.2rem;
  color: var(--sso-global-header-textColor);
  background-color: var(--sso-global-header-backgroundColor);
  border-bottom: 2px solid var(--sso-global-header-bottomBorderColor);
  display: flex;
  justify-content: var(--sso-global-header-logoAlignmentMobile);

  @include breakpoint('sm') {
    justify-content: var(--sso-global-header-logoAlignmentDesktop);
  }

  &__logo {
    display: block;
    line-height: 0;
  }

  &__custom-logo {
    display: block;
    line-height: 0;
    background-color: var(--sso-global-header-logoBackgroundColor);
    background-repeat: var(--sso-global-header-logoBackgroundRepeat);
    background-position: var(--sso-global-header-logoBackgroundPosition);
    background-size: var(--sso-global-header-logoBackgroundSize);
    width: var(--sso-global-header-logoWidth);
    height: var(--sso-global-header-logoHeight);
  }
}
</style>
