<script setup>
/* Props */
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
});
</script>

<template>
  <div class="portal-secondary-header">
    <g-heading
      :content="props.title"
      text-align="left"
      heading-size="2"
      theme="light"
    />
  </div>
</template>

<style lang="scss">
.portal-secondary-header {
  margin-bottom: 0.5rem;

  h2 {
    font-size: var(--sso-text-h2-fontSize);
  }
}
</style>
