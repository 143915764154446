<script setup>
/* Imports */
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import useGlobalStore from '../../store/globalStore';
import useViewStore from '../../store/viewStore';
import useVerifyAccountStore from '../../store/verifyAccountStore';
import useFormValidationStore from '../../store/formValidationStore';

import PortalPrimaryHeader from '../../components/PortalPrimaryHeader/PortalPrimaryHeader.vue';
import ValidationInput from '../../components/ValidationInput/ValidationInput.vue';
import ValidationForm from '../../components/ValidationForm/ValidationForm.vue';

import { getCurrentRoute } from '../../modules/helperFunctions';

/* Store */
const globalStore = useGlobalStore();
const viewStore = useViewStore();
const verifyAccountStore = useVerifyAccountStore();
const formValidationStore = useFormValidationStore();

/* Route */
const router = useRouter();
const currentRoute = getCurrentRoute(router);

/* State */
const state = reactive({
  phoneNumberInput: '',
  validation: {},
  formLoading: false,
});

const phoneNumberValidation = [
  formValidationStore.getIsRequired,
  formValidationStore.getIsPhone,
  formValidationStore.getIsCNPhone,
];

const handleSubmit = async () => {
  state.formLoading = true;
  const response = await verifyAccountStore.updatePhoneNumber({
    phoneNumber: state.phoneNumberInput,
  });
  state.formLoading = false;

  const {
    responseStatus,
    phoneNumber,
  } = response;
  const { type } = responseStatus;

  // Session timeout/expired, route to Sign-In
  if (type === 'REQUEST_FAIL' || type === 'SESSION_EXPIRED') {
    if (currentRoute.name === 'SignIn') {
      viewStore.setCurrentView('signIn');
    } else {
      router.push({ name: 'SignIn' });
    }

    return;
  }

  /* Route back to verifyAccount and display phoneNumber */
  if (type === 'SUCCESSFUL') {
    verifyAccountStore.setVerifyPhoneNumber(phoneNumber);
    viewStore.setCurrentView('verifyPhone');
  }
};

/* Cancel the form */
const cancelBtn = () => {
  viewStore.setCurrentView('verifyPhone');
};

/* Form validation */
const validateInput = (name, invalid) => {
  state.validation[name] = invalid;
};
</script>
<template>
  <div class="update-phone">
    <Portal-Primary-Header
      :title="globalStore.translations.verifyAccountSetPhonePageTitle"
    />
    <Validation-Form
      :submit-form="handleSubmit"
      :submit-btn-text="globalStore.translations.generalButtonUpdate"
      :cancel-btn-text="globalStore.translations.generalButtonCancel"
      :cancel-btn="cancelBtn"
      :validation="state.validation"
      :loading="state.formLoading"
    >
      <fieldset>
        <Validation-Input
          id="phoneNumber"
          v-model.trim="state.phoneNumberInput"
          name="phoneNumber"
          class="update-phone__form__input"
          :validation-rules="phoneNumberValidation"
          :label-text="globalStore.translations.generalTextMobilePhoneNumber"
          type="tel"
          required
          @validate-input="validateInput"
        />
      </fieldset>
    </Validation-Form>
  </div>
</template>
