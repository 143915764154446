import axios from 'axios';

/**
 * This is a wrapper helper to perform http requests using Axios.
 * You simply import it into your component and use it like this:
 * request({
 *  url: <THE_API_URL>              // This is required and will fail if not sent
 *  method: 'GET' / 'POST' / 'PUT'  // By default it is GET
 *  payload: ....                   // Used only for POST / PUT
 *  headers: {}                     // Custom headers that you want to set
 * });
 *
 * The function will return the output of the request
 * and you don't have to handle any Promises (usually).
 *
 *
 */

/**
 * Make a global config object with default values.
 * @param {object} [config]     - Optional config override.
 * @returns {object}            - Merged configuration object with default values.
 */
const makeConfig = (config) => {
  const defaults = {
    url: '',
    method: 'GET',
    payload: undefined,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (!config.url) throw new Error('url is required to make request');
  return {
    ...defaults,
    ...config,
  };
};

/**
 * The main request functionality to make HTTP requests using Axios.
 * @param {object} config                - Configuration for the HTTP request.
 * @param {string} config.url            - Required URL to request.
 * @param {string} [config.method]       - The request method (GET by default).
 * @param {string} [config.payload]      - Object representing the payload (used for POST/PUT).
 * @param {string} [config.headers]      - Object with custom headers.
 * @returns {object}                     - Response data from the HTTP request.
 */
const request = async (config = {}) => {
  const mergedConfig = makeConfig(config);
  const {
    method,
    url,
    headers,
    payload,
  } = mergedConfig;

  const response = await axios.request({
    method,
    url,
    headers,
    data: payload,
  });

  return response.data;
};

export default request;
