<script setup>
import { reactive, onMounted } from 'vue';
import useClientRegistriesStore from '../../store/clientRegistriesStore';
import useViewStore from '../../store/viewStore';
import useGlobalStore from '../../store/globalStore';
import baseUrls from '../../router/config/baseUrls';
/* Api URL */
const { apiUrl } = baseUrls;
/* Current View  */
const viewStore = useViewStore();
/* request which will be used to  send it on the backend on  GET,PUT,POST */
const { setRequest } = useGlobalStore();
const { getClientId } = useClientRegistriesStore();
/* State */
const state = reactive({
  sitekey: window?.GarminAppBootstrap?.recaptchaSiteKey,
  jsurl: window?.GarminAppBootstrap?.recaptchaJsUrl,
  widgetId: 0,
  locale: window?.GarminAppBootstrap?.locale,
});

/* emits  events to the  sign-in and createAccount forms using captcha */
const emit = defineEmits(['verify', 'expired', 'fail', 'widgetId']);

/* Payload to the AuditLogger */
const captchaAuditLogger = async (payload) => {
  await setRequest(
    {
      method: 'POST',
      url: `${apiUrl}/auditLog/external`,
      payload,
    },
  );
};

/* Methods */
const captchaCallback = async (response) => {
  emit('verify', response);
  await captchaAuditLogger({
    clientId: getClientId,
    apiName: `${viewStore.getCurrentView}`,
    apiParams: 'ACTION=captchaVerify',
    errorMessage: 'captchaVerify',
    exception: null,
    status: 'true',
  });
};

const captchaExpired = async () => {
  emit('expired');
  await captchaAuditLogger({
    clientId: getClientId,
    apiName: `${viewStore.getCurrentView}`,
    apiParams: 'ACTION=captchaExpired',
    errorMessage: 'captchaExpired',
    exception: null,
    status: 'true',
  });
};

const captchaError = async () => {
  emit('fail');
  await captchaAuditLogger({
    clientId: getClientId,
    apiName: `${viewStore.getCurrentView}`,
    apiParams: 'ACTION=captchaFailed',
    errorMessage: 'captchaFailed',
    exception: null,
    status: 'true',
  });
};

const render = () => {
  window.grecaptcha.enterprise.ready(() => {
    state.widgetId = window.grecaptcha.enterprise.render('portalCaptcha', {
      sitekey: state.sitekey,
      size: 'normal',
      tabIndex: 0,
      // the callback executed when the user solve the recaptcha
      callback: captchaCallback,
      'expired-callback': captchaExpired,
      'error-callback': captchaError,
    });
  });
};

/* handle captcha at onMounted */
onMounted(() => {
  if (!window.grecaptcha) {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute('id', 'captchascript');
    recaptchaScript.setAttribute('src', `${state.jsurl}${state.locale}`);
    recaptchaScript.setAttribute('async', '');
    recaptchaScript.setAttribute('defer', '');
    document.head.appendChild(recaptchaScript);
    document.getElementById('captchascript').onload = () => {
      render();
    };
  } else {
    render();
  }
});

</script>
<script>
/* eslint-disable no-param-reassign */
// these methods will be used by parent components
// that will use customcaptcha tag
const verifyCaptcha = (pstate) => {
  const response = window.grecaptcha.enterprise.getResponse();
  if (response.length > 0) {
    pstate.captchaToken = response;
    pstate.validation.captcha = false;
  } else {
    pstate.validation.captcha = true;
  }
};
const expiredCaptcha = (pstate) => {
  pstate.validation.captcha = true;
};

export {
  verifyCaptcha,
  expiredCaptcha,
};

</script>
<template>
  <div
    id="portalCaptcha"
    class="additional-required"
  />
</template>
