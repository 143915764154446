import { defineStore } from 'pinia';
import useGlobalStore from './globalStore';
import baseUrls from '../router/config/baseUrls';

const { apiUrl } = baseUrls;

/*
* This store has all contains data associated to consent
* To use this store import store and one of the getter function can be called as needed
* during the account creation process
* */
const bootStrap = () => window?.GarminAppBootstrap;
export default defineStore('consentStore', {
  state: () => ({
    consentTypeList: [],
    dcRequirePrivacyConsent: '',
    privacyConsentEnabledCountries: [],
    isCloudflareHeaderNeedsConsent: '',
    reConsentTypeList: [],
  }),
  getters: {
    getConsentTypeList: (state) => state.consentTypeList || [],
    getConsentType: (state) => state.consentTypeList?.consentType || '',
    getLabelText: (state) => state.consentTypeList?.labelText || '',
    getConsentText: (state) => state.consentText?.consentText || '',
    getConsentUrl: (state) => state.consentUrl?.consentUrl || '',
    getReconsentTypeList: (state) => state.reConsentTypeList || [],
    getDcRequirePrivacyConsent() {
      return this.dcRequirePrivacyConsent;
    },
    getPrivacyConsentEnabledCountries() {
      return this.privacyConsentEnabledCountries;
    },
    getIsCloudflareHeaderNeedsConsent() {
      return this.isCloudflareHeaderNeedsConsent;
    },
  },
  actions: {
    setConsentStoreData() {
      const consentTypeList = bootStrap().consentTypeList ? bootStrap().consentTypeList : [];
      /* eslint-disable-next-line prefer-destructuring */
      this.consentTypeList = consentTypeList;
    },
    setDcRequirePrivacyConsent() {
      this.dcRequirePrivacyConsent = bootStrap()
        ? bootStrap().dcRequirePrivacyConsent
        : false;
    },
    setIsCloudflareHeaderNeedsConsent() {
      this.isCloudflareHeaderNeedsConsent = bootStrap()
        ? bootStrap().isCloudflareHeaderNeedsConsent
        : false;
    },
    setPrivacyConsentEnabledCountries() {
      this.privacyConsentEnabledCountries = bootStrap()
        ? bootStrap().privacyConsentEnabledCountries
        : ['CN'];
    },
    setReconsentTypeList(list = []) {
      this.reConsentTypeList = list;
    },

    async setReconsent(payload) {
      const { setRequest } = useGlobalStore();

      const response = await setRequest(
        {
          method: 'POST',
          url: `${apiUrl}/reconsent`,
          payload,
        },
      );

      return response;
    },
  },
});
