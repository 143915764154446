import { defineStore } from 'pinia';
import useGlobalStore from './globalStore';

export default defineStore('viewStore', {
  state: () => ({
    currentView: 'signIn',
  }),
  getters: {
    getCurrentView: (state) => state.currentView,
    // Sign in views
    getShowSignIn() {
      return this.getCurrentView === 'signIn';
    },
    getShowForgotPassword() {
      return this.getCurrentView === 'forgotPassword';
    },
    getReConsentForm() {
      return this.getCurrentView === 'reConsentForm';
    },
    getShowChangePassword() {
      return this.getCurrentView === 'changePassword';
    },
    // Create account views
    getShowCreateAccount() {
      return this.getCurrentView === 'createAccount';
    },
    // Sign in and create account views
    getShowVerifyPhone() {
      return this.getCurrentView === 'verifyPhone';
    },
    getShowUpdatePhone() {
      return this.getCurrentView === 'updatePhone';
    },
    // MFA views
    getShowMfaRequired() {
      return this.getCurrentView === 'mfaRequired';
    },
    getShowMfaEnterCode() {
      return this.getCurrentView === 'mfaEnterCode';
    },
    getShowMfaOptions() {
      return this.getCurrentView === 'mfaOptions';
    },
    getShowMfaEnabled() {
      return this.getCurrentView === 'mfaEnabled';
    },
    getShowMfaAddPhoneNumber() {
      return this.getCurrentView === 'mfaAddPhoneNumber';
    },
    getShowSecurityCode() {
      return this.getCurrentView === 'securityCode';
    },
  },
  actions: {
    setCurrentView(flow, resetMsgs = true) {
      const { resetFlashMessages } = useGlobalStore();

      // clear all flash messages before changing views
      if (resetMsgs) resetFlashMessages();

      this.currentView = flow;
    },
  },
});
