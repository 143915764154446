export default (serviceURL, serviceTicketId) => {
  // Changes for NETAPP-39453 URL encoding enhancements
  const decodedServiceUrl = decodeURIComponent(serviceURL);
  const separator = decodedServiceUrl.includes('?') ? '&' : '?';
  let computedServiceURL = '';
  if (serviceURL.includes('?')) {
    const splitURL = serviceURL.split('?');
    const serviceURLPath = decodeURIComponent(splitURL[0]);
    const serviceURLParams = splitURL[1];
    if (serviceURLParams != null) {
      computedServiceURL = `${serviceURLPath}?${(serviceURLParams)}`;
    }
  } else {
    computedServiceURL = decodedServiceUrl;
  }
  window.location.href = `${computedServiceURL}${separator}ticket=${serviceTicketId}`;
};
