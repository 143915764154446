<script setup>
/* Imports */
import { computed, reactive } from 'vue';
import { useRouter } from 'vue-router';
import useGlobalStore from '../../store/globalStore';
import useFormValidationStore from '../../store/formValidationStore';
import useViewStore from '../../store/viewStore';
import useSecurityStore from '../../store/securityCodeStore';
import useCreateAccountStore from '../../store/createAccountStore';
import useMfaStore from '../../store/mfaStore';

import PortalPrimaryHeader from '../../components/PortalPrimaryHeader/PortalPrimaryHeader.vue';
import PortalSecondaryHeader from '../../components/PortalSecondaryHeader/PortalSecondaryHeader.vue';
import ValidationInput from '../../components/ValidationInput/ValidationInput.vue';
import ValidationForm from '../../components/ValidationForm/ValidationForm.vue';

import redirectToServiceUrl from '../../modules/redirectToServiceUrl';
import { buildOrConcatTemplate } from '../../modules/helperFunctions';
import redirectWithSAML from '../../modules/postSAMLResponse';

/* Router */
const router = useRouter();

/* Store */
const globalStore = useGlobalStore();
const formValidationStore = useFormValidationStore();
const viewStore = useViewStore();
const securityStore = useSecurityStore();
const createAccountStore = useCreateAccountStore();
const mfaStore = useMfaStore();

/* Locale */
const locale = globalStore.getLocale;

/* Helper */
const helpLink = `https://support.garmin.com/${locale}/`;

/* Props */
const props = defineProps({
  pageHeader: {
    type: String,
    default: '',
  },
});

/* State */
const state = reactive({
  securityCode: null,
  validation: {},
  formLoading: false,
  emailValue: computed(() => securityStore.getMaskedEmail),
  requestCodeDisabled: false,
  consents: {},
});

const verificationMessage = computed(() => buildOrConcatTemplate(
  globalStore.translations.codeEntrySentCode,
  [state.emailValue],
  '{0}',
));

const securityCodeValidation = [formValidationStore.getIsMfaCodeValid];

const handlePromoteToFullAccount = async () => {
  const responsePromoteCustomer = await createAccountStore.setPromoteAccount();

  const {
    authType,
    serviceURL,
    serviceTicketId,
    responseStatus,
    samlResponse,
  } = responsePromoteCustomer;
  const { type } = responseStatus;

  if (type === 'PHONE_VERIFICATION_REQUIRED') {
    viewStore.setCurrentView('verifyPhone');

    return;
  }

  if (mfaStore.getSetupMfa) {
    router.push({ name: 'Mfa' });

    return;
  }

  if (type === 'SUCCESSFUL') {
    if (authType === 'SAML') {
      redirectWithSAML(serviceURL, samlResponse);
    }
    redirectToServiceUrl(serviceURL, serviceTicketId);
  }
};

const handleSubmit = async () => {
  state.formLoading = true;
  const response = await securityStore.verifySecurityCode({
    securityCode: state.securityCode,
  });

  const { responseStatus } = response;
  const { type } = responseStatus;

  // Session Expired
  if (type === 'SESSION_EXPIRED') {
    router.push({ name: 'CreateAccount' });
    return;
  }

  if (type === 'SUCCESSFUL') {
    await handlePromoteToFullAccount();
    return;
  }

  state.formLoading = false;
};

const cancelBtn = () => {
  viewStore.setCurrentView('createAccount');
};

const requestNewCode = async () => {
  // don't make a new code request if request is disabled
  if (state.requestCodeDisabled) return;
  state.requestCodeDisabled = true;

  const response = await securityStore.requestNewSecurityCode();

  const { responseStatus } = response;
  const { type } = responseStatus;

  // Session Expired
  if (type === 'SESSION_EXPIRED') {
    router.push({ name: 'CreateAccount' });
  }

  if (type === 'SECURITY_CODE_SENT') {
    setTimeout(() => {
      // clear flash messages so new code message
      globalStore.resetFlashMessages();

      state.requestCodeDisabled = false;
    }, 30000);
  } else {
    state.requestCodeDisabled = false;
  }
};

const validateInput = (name, invalid) => {
  state.validation[name] = invalid;
};

</script>
<template>
  <div class="security-enter-code">
    <Portal-Primary-Header :title="props.pageHeader" />
    <Portal-Secondary-Header
      :title="globalStore.translations.codeEntryHeading"
    />
    <g-copy
      type="large"
    >
      {{ verificationMessage }}
    </g-copy>
    <Validation-Form
      class="security-enter-code__form"
      :submit-form="handleSubmit"
      :submit-btn-text="globalStore.translations.generalButtonNext"
      :cancel-btn-text="globalStore.translations.generalButtonGoBack"
      :cancel-btn="cancelBtn"
      :validation="state.validation"
      :loading="state.formLoading"
    >
      <fieldset class="security-enter-code__form__input">
        <Validation-Input
          id="securityCode"
          v-model="state.securityCode"
          class="security-enter-code__form__input"
          :validation-rules="securityCodeValidation"
          :label-text="globalStore.translations.codeEntryCodeEntryLabel"
          name="securityCode"
          type="number"
          required
          @validate-input="validateInput"
        />
      </fieldset>
      <div class="security-enter-code__form__links">
        <button
          id="request_new_code"
          type="button"
          class="security-enter-code__form__link-btn"
          :disabled="state.requestCodeDisabled"
          @click="requestNewCode"
        >
          {{ globalStore.translations.codeEntryRequestNewCode }}
        </button>
        <a
          :href="helpLink"
          target="_blank"
          class="security-enter-code__form__link-btn"
        >
          {{ globalStore.translations.codeEntryHavingTrouble }}
        </a>
      </div>
    </Validation-Form>
  </div>
</template>

<style lang="scss">
.security-enter-code__form {
  &__consent-text {
    margin-left: 30px;
  }

  &__input {
    .validation-input {
      margin-bottom: 0.5em;
    }
  }

  &__checkbox {
    margin-bottom: 1em;
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2em;
  }

  &__link-btn {
    background: transparent;
    border: 0;
    color: var(--sso-text-link-textColor);
    cursor: pointer;
    font-family: var(--sso-text-body-fontStack);
    font-size: var(--sso-text-body-fontSize);
    font-weight: 400;
    text-decoration: underline;
    padding: 0;
    margin-bottom: 1em;

    &:disabled {
      color: $color-gray-45;
      cursor: not-allowed;
    }
  }
}
</style>
