<script setup>
/* Imports */
import { reactive } from 'vue';
import useGlobalStore from '../../store/globalStore';
import { buildTemplate } from '../../modules/helperFunctions';

/* Store */
const globalStore = useGlobalStore();

/* Props */
const props = defineProps({
  type: {
    type: String,
    default: 'large',
  },
});

/* State */
const state = reactive({
  termsOfUseUrl: globalStore.getTermOfUseUrl,
  privacyStatementUrl: globalStore.getPrivacyUrl,
});

const privacyStatement = buildTemplate(
  globalStore.translations.setupMfaOptInContent,
  [`<a class="create-form__privacy__link" target="_blank" href="${state.privacyStatementUrl}">`, '</a>',
    `<a class="create-form__termsofUse__link" target="_blank" href="${state.termsOfUseUrl}">`, '</a>'],
);

</script>

<template>
  <div class="portal-disclaimer">
    <g-copy
      :type="props.type"
      v-html="privacyStatement"
    />
  </div>
</template>
