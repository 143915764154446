import { defineStore } from 'pinia';
import useGlobalStore from './globalStore';

import baseUrls from '../router/config/baseUrls';

const { apiUrl } = baseUrls;
const securityCodeApiUrl = `${apiUrl}/securityCode`;

export default defineStore('securityCode', {
  state: () => ({
    securityCodeInfo: null,
  }),
  getters: {
    getSecurityCodeInfo: (state) => state.securityCodeInfo,
    getMaskedEmail() {
      return this.getSecurityCodeInfo?.email || '';
    },
  },
  actions: {
    setSecurityCodeInfo(info) {
      this.securityCodeInfo = { ...info };
    },
    async verifySecurityCode(payload) {
      const { setRequest } = useGlobalStore();
      const customAlerts = {
        SECURITY_CODE_INVALID: 'warning',
        SECURITY_CODE_EXPIRED: 'warning',
      };

      const response = await setRequest(
        {
          method: 'POST',
          url: `${securityCodeApiUrl}/verifySecurityCode`,
          payload,
        },
        customAlerts,
      );

      return response;
    },
    async requestNewSecurityCode() {
      const { setRequest } = useGlobalStore();

      const response = await setRequest({
        method: 'POST',
        url: `${securityCodeApiUrl}/sendCode`,
      });

      return response;
    },
  },
});
