<script>
/**
 * https://vuejs.org/guide/components/attrs.html#disabling-attribute-inheritance
 */
export default {
  inheritAttrs: false,
};
</script>

<script setup>
/* eslint-disable import/first */
/* Imports */
import {
  computed, reactive, useAttrs, watch,
} from 'vue';
import useGlobalStore from '../../store/globalStore';

import RenderString from '../RenderString/RenderString.vue';

import { buildTemplate } from '../../modules/helperFunctions';

/* Props */
const props = defineProps({
  validationRules: {
    type: Array,
    default() {
      return [];
    },
  },
  labelText: {
    type: String,
    default: '',
  },
  modelValue: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  floatingLabel: {
    type: String,
    default: '',
  },
  floatingLabelState: {
    type: Boolean,
    default: false,
  },
  helpText: {
    type: String,
    default: '',
  },
  emailError: {
    type: Boolean,
    default: false,
  },
  triggerValidation: {
    type: Boolean,
    default: false,
  },
  validateOnKeyUp: {
    type: Boolean,
    default: true,
  },
});

/* Emits */
const emit = defineEmits([
  'update:modelValue',
  'validate-input',
  'show-password',
]);

/* Store */
const globalStore = useGlobalStore();

/* Attributes */
const attrs = useAttrs();

/* State */
const state = reactive({
  validationError: '',
  inValid: computed(() => !!state.validationError.length),
  showEmailError: computed(() => props.emailError),
});

/* Helpers */
const showHelpText = !!props.helpText.length;
const getHelpTextID = `${attrs.id}-helper-text`;

/* Input validation */
const validateRules = (input, validationRules) => validationRules.reduce((acc, f) => {
  const result = f(input);
  if (result !== true) return [...acc, result];

  return acc;
}, []);

const validate = (event) => {
  if (props.validationRules.length > 0) {
    const validationResults = validateRules(props.modelValue, props.validationRules);

    state.validationError = validationResults.find((err) => !!err) || '';

    /*
     Force syncing of reactive state to DOM attribute right now. The normal sync done by Vue
     happens too late to be visible in handleSubmit function of ValidationForm component.
     */
    event?.target?.setAttribute('inValid', state.inValid);

    emit('validate-input', attrs.name, state.inValid);
  }
};

const handleInput = (event) => {
  emit('update:modelValue', event.target.value);

  if (props.validationRules.length > 0) {
    const validationResults = validateRules(event.target.value, props.validationRules);
    const hasValidationErrors = !!validationResults.find((err) => !!err);

    if (!hasValidationErrors) {
      // If validation errors are gone, clear them, but do not set new error messages if new errors have appeared.
      // If we intend the new errors to be displayed after each change then validateOnKeyUp should be set to true.
      state.validationError = '';

      /*
       Force syncing of reactive state to DOM attribute right now. The normal sync done by Vue
       happens too late to be visible in handleSubmit function of ValidationForm component.
       */
      event.target.setAttribute('inValid', state.inValid);

      emit('validate-input', attrs.name, state.inValid);
    }
  }
};

const keyup = () => {
  if (props.validateOnKeyUp) {
    /*
       TODO [Adrian.Nistor] why do we emit an event regarding validation state if we haven't
       updated state.validationError? The validation seems to have been done in keydown event handler but that
       was removed in commit f9acfda526e779bf6a204532b48587c79408fc16
     */
    emit('validate-input', attrs.name, state.inValid);
  }
};

if (props.triggerValidation) validate();

/* Validate field if triggerValidation prop changes */
watch(() => props.triggerValidation, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    validate();
  }
});

/* Translated duplicate email error */
const emailErrorString = buildTemplate(
  globalStore.translations.createAccEmailExists,
  ['<router-link :to="{ name: \'SignIn\' }">', '</router-link>'],
);
</script>

<template>
  <div class="validation-input validation-form__input">
    <g-input
      :label="props.labelText"
      :label-for="attrs.id"
      :error="!!state.validationError"
      :required="props.required"
      :floating-label="props.floatingLabel"
      @g-click-floating-label="$emit('show-password')"
    >
      <input
        v-bind="$attrs"
        :value="modelValue"
        :required="props.required"
        :inValid="state.inValid"
        :readonly="props.readOnly"
        :aria-describedby="showHelpText ? getHelpTextID : null"
        @input="handleInput"
        @blur="validate"
        @keyup="keyup"
      >
    </g-input>
    <g-error
      v-if="state.validationError"
      class="validation-input__error"
    >
      {{ state.validationError }}
    </g-error>
    <g-error
      v-else-if="state.showEmailError"
      class="validation-input__error"
    >
      <Render-String :content="emailErrorString" />
    </g-error>
    <g-help-text
      v-else-if="showHelpText"
      :id="getHelpTextID"
      class="validation-input__help"
    >
      {{ props.helpText }}
    </g-help-text>
  </div>
</template>

<style lang="scss" scoped>
.validation-input {
  &__error {
    margin-top: 0.5rem;

    a {
      font-size: 0.8rem;
    }
  }
}
</style>
