<script setup>
/* Imports */
import { compile, h } from 'vue';

/* Props */
const props = defineProps({
  content: {
    type: String,
    default: '',
  },
});

/* render function */
const render = () => h(
  compile(props.content),
);
</script>

<template>
  <render />
</template>
