<template>
  <div class="portal-footer">
    <p>
      <slot />
    </p>
  </div>
</template>

<style lang="scss">
.portal-footer {
  text-align: center;
}
</style>
