<script setup>
/* Imports */
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import useGlobalStore from '../../store/globalStore';
import useMfaStore from '../../store/mfaStore';
import useFormValidationStore from '../../store/formValidationStore';
import useViewStore from '../../store/viewStore';

import ValidationInput from '../../components/ValidationInput/ValidationInput.vue';
import ValidationForm from '../../components/ValidationForm/ValidationForm.vue';
import PortalPrimaryHeader from '../../components/PortalPrimaryHeader/PortalPrimaryHeader.vue';
import PortalDisclaimer from '../PortalDisclaimer/PortalDisclaimer.vue';

import countries from '../../config/countriesExport.json';

/* Store */
const globalStore = useGlobalStore();
const mfaStore = useMfaStore();
const formValidationStore = useFormValidationStore();
const viewStore = useViewStore();

/* Route */
const router = useRouter();

/* Locale */
const localeCountry = globalStore.getLocaleCountry;

/* Props */
const props = defineProps({
  pageHeader: {
    type: String,
    default: '',
  },
});

/* State */
const state = reactive({
  isoCountryCode: localeCountry,
  areaCode: '',
  phoneNumber: '',
  formLoading: false,
  validation: {},
});

/* Fields validation */
const areaCodeValidation = [formValidationStore.getIsAreaCode];
const phoneNumberValidation = [formValidationStore.getIsRequired, formValidationStore.getIsPhone];

/* Handle form submit */
const handleSubmit = async () => {
  state.formLoading = true;
  const { phoneCode } = countries.find((country) => (
    country.locale.country === state.isoCountryCode
  ));
  const response = await mfaStore.updateMfaInfo({
    countryCode: phoneCode,
    isoCountryCode: state.isoCountryCode,
    areaCode: state.areaCode,
    phoneNumber: state.phoneNumber,
    mfaMethodToBeUsed: 'phone',
  });
  state.formLoading = false;

  const {
    responseStatus,
    phoneNumber,
  } = response;
  const { type } = responseStatus;

  // Session Expired
  if (type === 'SESSION_EXPIRED') {
    router.push({ name: 'SignIn' });
  }

  // Code sent - redirect to enter code
  if (type === 'MFA_CODE_SENT') {
    // set mfa option to phone
    mfaStore.setMfaOption('phone');

    // save new phone number to store
    mfaStore.setMfaPhone(phoneNumber);

    viewStore.setCurrentView('mfaEnterCode');
  }
};

/* Cancel the form */
const cancelBtn = () => {
  viewStore.setCurrentView('mfaOptions');
};

/* Form validation */
const validateInput = (name, invalid) => {
  state.validation[name] = invalid;
};
</script>

<template>
  <div class="add-phone-number">
    <Portal-Primary-Header
      :title="props.pageHeader"
    />
    <g-copy
      type="large"
    >
      {{ globalStore.translations.mfaPhoneNumberEntryOptInTextOne }}
    </g-copy>
    <Validation-Form
      :submit-form="handleSubmit"
      :submit-btn-text="globalStore.translations.generalButtonAcceptContinue"
      :cancel-btn-text="globalStore.translations.generalButtonDecline"
      :cancel-btn="cancelBtn"
      :validation="state.validation"
      :loading="state.formLoading"
    >
      <fieldset>
        <g-drop-down
          class="validation-form__input"
          :label="globalStore.translations.mfaPhoneNumberEntryHeadingOne"
        >
          <select
            id="country-region"
            v-model="state.isoCountryCode"
            autocomplete="tel-country-code"
          >
            <option
              v-for="(country, index) in countries"
              :key="index"
              :value="country.locale.country"
            >
              {{ country.label }} ({{ country.phoneCode }})
            </option>
          </select>
        </g-drop-down>
      </fieldset>
      <fieldset>
        <Validation-Input
          id="areaCode"
          v-model="state.areaCode"
          :validation-rules="areaCodeValidation"
          :label-text="globalStore.translations.mfaPhoneNumberEntryHeadingTwo"
          maxlength="6"
          name="areaCode"
        />
      </fieldset>
      <fieldset>
        <Validation-Input
          id="phone"
          v-model="state.phoneNumber"
          :validation-rules="phoneNumberValidation"
          :label-text="globalStore.translations.mfaPhoneNumberEntryHeadingThree"
          required
          name="Phone Number"
          type="tel"
          @validate-input="validateInput"
        />
      </fieldset>
      <Portal-Copy-Con>
        <Portal-Disclaimer />
      </Portal-Copy-Con>
    </Validation-Form>
  </div>
</template>
