<script setup>
/* Imports */
import { computed, onBeforeMount, reactive } from 'vue';
import { useRouter } from 'vue-router';
import useClientRegistriesStore from '../../store/clientRegistriesStore';
import useGlobalStore from '../../store/globalStore';
import useMfaStore from '../../store/mfaStore';
import useCreateAccountStore from '../../store/createAccountStore';
import useFormValidationStore from '../../store/formValidationStore';
import useViewStore from '../../store/viewStore';
import useConsentStore from '../../store/consentStore';

import PasswordInput from '../PasswordInput/PasswordInput.vue';
import PortalPrimaryHeader from '../../components/PortalPrimaryHeader/PortalPrimaryHeader.vue';
import ValidationInput from '../../components/ValidationInput/ValidationInput.vue';
import PortalFooterSignIn from '../PortalFooterSignIn/PortalFooterSignIn.vue';
import ValidationForm from '../../components/ValidationForm/ValidationForm.vue';
import CustomCaptcha, { verifyCaptcha, expiredCaptcha } from '../../components/Recaptcha/CustomCaptcha.vue';
import redirectToServiceUrl from '../../modules/redirectToServiceUrl';
import { buildTemplate } from '../../modules/helperFunctions';
import countries from '../../config/countriesExport.json';
import useSecurityCodeStore from '../../store/securityCodeStore';
import redirectWithSAML from '../../modules/postSAMLResponse';

/* Store */
const clientRegistriesStore = useClientRegistriesStore();
const createAccountStore = useCreateAccountStore();
const mfaStore = useMfaStore();
const globalStore = useGlobalStore();
const formValidationStore = useFormValidationStore();
const viewStore = useViewStore();
const consentStore = useConsentStore();
const securityCodeStore = useSecurityCodeStore();

/* Route */
const router = useRouter();

/* Locale */
const defaultCountrySelect = globalStore.getDefaultCountrySelect;
const isPhoneRegistration = globalStore.getPhoneRegistrationRequired;

/* State */
const state = reactive({
  nameInput: '',
  emailInput: '',
  confirmEmailInput: '',
  passwordInput: '',
  confirmPasswordInput: '',
  legalAge: createAccountStore.getLegalAge,
  /* Submit form with locale only when locationPromptShown is enabled */
  locationSelect: clientRegistriesStore.getLocationPromptShown ? defaultCountrySelect : '',
  phoneNumberInput: '',
  captchaToken: '',
  isCaptchaEnable: window?.GarminAppBootstrap?.isCaptchaEnabled,
  captchaAlreadyPassed: false,
  termsOfUseUrl: globalStore.getTermOfUseUrl,
  termsOfUseInput: false,
  /* eslint-disable max-len */
  privacyStatementUrl: globalStore.getPrivacyUrl,
  privacyPolicyInput: false,
  displayGlobalOptInInput: clientRegistriesStore.getGlobalOptInShown,
  globalOptInInput: false,
  validation: {},
  formLoading: false,
  emailError: false,
  consentTypes: [],
  locationPromptShown: clientRegistriesStore.getLocationPromptShown,
  showConnectLegalAge: clientRegistriesStore.getShowConnectLegalAge,
  triggerEmailValidation: false,
  triggerConfirmEmailValidation: false,
  triggerPasswordValidation: false,
  triggerConfirmPasswordValidation: false,
  isConsentRequired: computed(() => {
    if (consentStore.getConsentTypeList.length === 0) {
      return false;
    }
    if (consentStore.getDcRequirePrivacyConsent) {
      return consentStore.getDcRequirePrivacyConsent;
    } if (state.locationPromptShown) {
      return consentStore.getPrivacyConsentEnabledCountries.includes(
        state.locationSelect,
      );
    } if (consentStore.getIsCloudflareHeaderNeedsConsent) {
      return true;
    }
    return false;
  }),
  consentList: computed(() => {
    if (state.isConsentRequired) {
      return consentStore.getConsentTypeList;
    }
    return [];
  }),
});

/* Legal Age */
onBeforeMount(async () => {
  await createAccountStore.setLegalAge(defaultCountrySelect);
  state.legalAge = createAccountStore.getLegalAge;
});

/*
 * If location is pre-selected by locale,
 *  manually set validation to valid
 */
if (state.locationSelect && state.locationPromptShown) state.validation.country = false;

const privacyStatement = buildTemplate(
  globalStore.translations.legalPrivacyStatement,
  [`<a class="create-form__privacy__link" target="_blank" href="${state.privacyStatementUrl}">`, '</a>'],
);

/* Translated legal age */
const legalConfirmAge = computed(() => buildTemplate(
  globalStore.translations.legalConfirmAge,
  [state.legalAge],
));

/* Email validation */
/* eslint-disable max-len */
const matchesEmailValue = (value) => {
  if (state.emailInput !== '') {
    state.triggerEmailValidation = value !== state.emailInput;
  }
  return value === state.emailInput || globalStore.translations.generalValidationEmailDontMatch;
};

const matchesConfirmEmailValue = (value) => {
  if (state.confirmEmailInput !== '') {
    state.triggerConfirmEmailValidation = value !== state.confirmEmailInput;

    return value === state.confirmEmailInput
      || globalStore.translations.generalValidationEmailDontMatch;
  }
  return true;
};

/* Password validation */
/* eslint-disable max-len */
const matchesPasswordValue = (value) => {
  if (state.passwordInput !== '') {
    state.triggerPasswordValidation = value !== state.passwordInput;
  }
  return value === state.passwordInput || globalStore.translations.generalValidationPasswordsDontMatch;
};

const matchesConfirmPasswordValue = (value) => {
  if (state.confirmPasswordInput !== '') {
    state.triggerConfirmPasswordValidation = value !== state.confirmPasswordInput;

    return value === state.confirmPasswordInput
      || globalStore.translations.generalValidationPasswordsDontMatch;
  }
  return true;
};

/* Fields validation */
const nameValidation = [formValidationStore.getIsRequired, formValidationStore.getIsName];
const emailValidation = [
  formValidationStore.getIsRequired,
  formValidationStore.getIsEmail,
  matchesConfirmEmailValue,
];
const confirmEmailValidation = [formValidationStore.getIsRequired, matchesEmailValue];
const passwordValidation = [formValidationStore.getIsPassword, matchesConfirmPasswordValue];
const confirmPasswordValidation = [formValidationStore.getIsPassword, matchesPasswordValue];
const phoneNumberValidation = [
  formValidationStore.getIsRequired,
  formValidationStore.getIsPhone,
  formValidationStore.getIsCNPhone,
];

/* Form validation */
const validateCheckbox = (event) => {
  state.validation[event.target.name] = !event.target.checked;
};

const validateDropdown = (event) => {
  if (state.showConnectLegalAge) {
    // When the Country/Region field is changed
    if (event.target.getAttribute('id') === 'country') {
      (async () => {
        // Use the selected country/region to update the legal age
        await createAccountStore.setLegalAge(event.target.value);
        state.legalAge = createAccountStore.getLegalAge;
      })();
    }
  }
  state.validation[event.target.name] = event.target.value === '';
};

const validateInput = async (name, invalid) => {
  state.validation[name] = invalid;

  if (name === 'email') {
    // Clear any invalid messaging
    state.emailError = false;
    globalStore.resetFlashMessages();
  }
};

/* Handle submit the form */
const handleSubmit = async () => {
  state.formLoading = true;
  const createAccountRes = await createAccountStore.setCreateAccount({
    name: state.nameInput,
    email: state.emailInput,
    username: state.confirmEmailInput,
    password: state.passwordInput,
    phoneNumber: state.phoneNumberInput,
    globalOptIn: state.globalOptInInput,
    location: state.locationSelect,
    consentTypeList: state.consentList,
    captchaToken: state.captchaToken,

  });

  const {
    authType,
    serviceURL,
    serviceTicketId,
    responseStatus,
    securityCodeInfo,
    captchaAlreadyPassed,
    samlResponse,
  } = createAccountRes;
  const { type } = responseStatus;

  if (captchaAlreadyPassed) {
    delete state.validation.captcha;
    state.captchaAlreadyPassed = captchaAlreadyPassed;
    state.isCaptchaEnable = !captchaAlreadyPassed;
    state.formLoading = false;
    const additionalRequiredElements = document.querySelectorAll('.additional-required');
    if (additionalRequiredElements.length > 0) {
      additionalRequiredElements.item(0).classList.remove('.additional-required');
      console.log(additionalRequiredElements);
    }
  }

  /* reset captcha after submit */
  if (!captchaAlreadyPassed && window.grecaptcha && window.grecaptcha.enterprise) {
    window.grecaptcha.enterprise.reset();
  }

  /* Redirect to Verify Phone if required */
  if (type === 'PHONE_VERIFICATION_REQUIRED') {
    viewStore.setCurrentView('verifyPhone');

    return;
  }

  if (type === 'CAPTCHA_REQUIRED') {
    state.formLoading = false;
    state.isCaptchaEnable = true;
    state.validation.captcha = true;
    viewStore.setCurrentView('createAccount');
    return;
  }

  /* Redirect to MFA only if MFA setup is required */
  if (mfaStore.getSetupMfa) {
    router.push({ name: 'Mfa' });

    return;
  }

  if (globalStore.getAccountVerificationFlagEnabled && type === 'SECURITY_CODE_REQUIRED') {
    viewStore.setCurrentView('securityCode');
    securityCodeStore.setSecurityCodeInfo(securityCodeInfo);
    return;
  }

  const typeActions = {
    SUCCESSFUL: () => {
      if (authType === 'SAML') {
        redirectWithSAML(serviceURL, samlResponse);
        return;
      }
      redirectToServiceUrl(serviceURL, serviceTicketId);
    },
    EMAIL_TAKEN: () => {
      state.emailError = true;
      state.validation.email = true;
    },
    PENDING_DELETE: () => {
      state.validation.email = true;
    },
    INVALID_EMAIL: () => {
      state.validation.email = true;
    },
  };
  // reset the spinner before returning from the function
  state.formLoading = false;
  const action = typeActions[type];
  if (action) {
    action();
  }
};

</script>

<template>
  <div class="create">
    <Portal-Primary-Header :title="globalStore.translations.createAccPageTitle" />
    <Validation-Form
      class="create-form"
      :submit-form="handleSubmit"
      :submit-btn-text="globalStore.translations.generalButtonCreateAccount"
      :validation="state.validation"
      :loading="state.formLoading"
      :allow-autofill="false"
    >
      <fieldset class="create-form__input">
        <Validation-Input
          id="name"
          v-model="state.nameInput"
          class="create-form__input"
          required
          :validation-rules="nameValidation"
          :label-text="globalStore.translations.generalTextName"
          name="name"
          type="text"
          @validate-input="validateInput"
        />
      </fieldset>
      <fieldset class="create-form__input">
        <Validation-Input
          id="email"
          v-model.trim="state.emailInput"
          class="create-form__input"
          required
          :validation-rules="emailValidation"
          :label-text="globalStore.translations.generalTextEmailOrUsername"
          name="email"
          type="email"
          maxlength="100"
          :email-error="state.emailError"
          :validate-on-key-up="false"
          :trigger-validation="state.triggerEmailValidation"
          @validate-input="validateInput"
        />
      </fieldset>
      <fieldset class="create-form__input">
        <Validation-Input
          id="confirmEmail"
          v-model.trim="state.confirmEmailInput"
          class="create-form__input"
          required
          :validation-rules="confirmEmailValidation"
          :label-text="globalStore.translations.casLoginViewRetypeEmailAddress"
          name="confirmEmail"
          type="email"
          maxlength="100"
          onpaste="return false;"
          :trigger-validation="state.triggerConfirmEmailValidation"
          @validate-input="validateInput"
        />
      </fieldset>
      <fieldset class="create-form__input">
        <Password-Input
          id="password"
          v-model="state.passwordInput"
          class="create-form__input"
          required
          :validation-rules="passwordValidation"
          :label-text="globalStore.translations.generalTextPassword"
          name="password"
          :help-text="globalStore.translations.generalTextPasswordHint"
          :trigger-validation="state.triggerPasswordValidation"
          @validate-input="validateInput"
        />
      </fieldset>
      <fieldset class="create-form__input">
        <Password-Input
          id="confirmPassword"
          v-model="state.confirmPasswordInput"
          class="create-form__input"
          required
          :validation-rules="confirmPasswordValidation"
          :label-text="globalStore.translations.generalTextRetypePassword"
          name="confirmPassword"
          on-paste="return false;"
          :trigger-validation="state.triggerConfirmPasswordValidation"
          @validate-input="validateInput"
        />
      </fieldset>
      <fieldset
        v-if="state.locationPromptShown"
        class="create-form__input dropdown"
      >
        <div class="validation-input validation-form__select">
          <g-drop-down
            class="create-form__select"
            :label="globalStore.translations.generalTextLocationPromptCountryOrRegion"
            aria-describedby="countryHelpText"
            required
          >
            <select
              id="country"
              v-model="state.locationSelect"
              name="country"
              autocomplete="country"
              required
              @change="validateDropdown($event)"
            >
              <option
                v-for="country in countries"
                :key="country.locale.country"
                :value="country.locale.country"
              >
                {{ country.label }}
              </option>
            </select>
          </g-drop-down>
          <g-help-text id="countryHelpText">
            {{ globalStore.translations.generalTextLocationPromptHint }}
          </g-help-text>
        </div>
      </fieldset>
      <fieldset
        v-if="isPhoneRegistration"
        class="create-form__input"
      >
        <Validation-Input
          id="phoneNumber"
          v-model.trim="state.phoneNumberInput"
          class="create-form__input"
          required
          :validation-rules="phoneNumberValidation"
          :label-text="globalStore.translations.generalTextPhoneNumber"
          name="phoneNumber"
          type="tel"
          @validate-input="validateInput"
        />
      </fieldset>
      <fieldset
        v-if="state.displayGlobalOptInInput"
        class="create-form__input checkbox"
      >
        <g-checkbox
          :label="globalStore.translations.createAccConnectEmailSignupText"
        >
          <input
            id="emailSignUp"
            v-model="state.globalOptInInput"
            name="productNews"
            type="checkbox"
          >
        </g-checkbox>
      </fieldset>
      <fieldset
        v-if="state.showConnectLegalAge"
        class="create-form__input checkbox"
      >
        <g-checkbox :label="legalConfirmAge">
          <input
            id="connectLegalAge"
            v-model="state.connectLegalAgeInput"
            name="connectLegalAge"
            type="checkbox"
            required
            @change="validateCheckbox($event)"
          >
        </g-checkbox>
      </fieldset>
      <fieldset class="create-form__input checkbox">
        <g-checkbox :label="globalStore.translations.legalAcceptTerms">
          <input
            id="termsOfUse"
            v-model="state.termsOfUseInput"
            name="termsOfUse"
            type="checkbox"
            required
            @change="validateCheckbox($event)"
          >
        </g-checkbox>
        <p class="create-form__terms__container">
          <a
            id="terms-of-use-url"
            :href="state.termsOfUseUrl"
            target="_blank"
          >
            {{ globalStore.translations.legalTermsOfUse }}
          </a>
        </p>
      </fieldset>
      <template
        v-if="state.isConsentRequired"
      >
        <fieldset
          v-for="(consent, index) in consentStore.getConsentTypeList"
          :key="consent"
          class="create-form__input checkbox"
        >
          <g-checkbox :label="consent.labelText !== null ? consent.labelText : globalStore.translations.legalAcceptPrivacy">
            <input
              :id="consent.consentType"
              v-model="state.consentTypes[index]"
              :name="consent.consentType"
              type="checkbox"
              required
              @change="validateCheckbox($event)"
            >
          </g-checkbox>
          <p class="create-form__terms__container">
            <a :href="consent.consentUrl">
              {{ consent.consentText }}
            </a>
          </p>
        </fieldset>
        <p class="consent-end" />
      </template>
      <template v-if="!state.isConsentRequired">
        <fieldset
          v-if="isChina"
          class="create-form__input checkbox"
        >
          <g-checkbox
            :label="globalStore.translations.legalAcceptPrivacyPolicy"
          >
            <input
              id="privacyPolicy"
              v-model="state.privacyPolicyInput"
              name="privacyPolicy"
              type="checkbox"
              required
              @change="validateCheckbox($event)"
            >
          </g-checkbox>
          <p class="create-form__privacy__container">
            <a :href="state.privacyStatementUrl">
              {{ globalStore.translations.mfaPhoneNumberEntryPrivacyPolicy }}
            </a>
          </p>
        </fieldset>
        <p
          v-if="!isChina"
          class="privacy-statement"
          v-html="privacyStatement"
        />
      </template>
      <CustomCaptcha
        v-if="state.isCaptchaEnable && !state.captchaAlreadyPassed"
        @verify="verifyCaptcha(state)"
        @expired="expiredCaptcha(state)"
      />
    </Validation-Form>
    <Portal-Footer-Sign-In />
  </div>
</template>

<style lang="scss" scoped>
.create-form {
  &__helper__text {
    margin-bottom: 3rem;
    font-size: 0.8em;
  }

  &__terms__container,
  &__privacy__container {
    padding-left: 1.85em;
  }

  &__privacy__container {
    margin-bottom: 2rem;
  }

  .privacy-statement {
    margin-top: 1rem;
    margin-bottom: 3rem;
  }

  .consent-end {
    margin-bottom: 3rem;
  }
}

.validation-input {
  margin-bottom: 1.5em;
}
</style>
