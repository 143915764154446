<template>
  <div class="portal-copy-con">
    <slot />
  </div>
</template>

<style lang="scss">
.portal-copy-con {
  margin-bottom: 3em;
}
</style>
