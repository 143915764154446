<script setup>
/* Imports */
import useGlobalStore from '../../store/globalStore';

/* Store */
const globalStore = useGlobalStore();

const onClose = (index) => globalStore.removeFlashMessage(index);
</script>
<template>
  <div class="flash-msg">
    <g-alert
      v-for="(msg, index) in globalStore.getFlashMessages"
      :key="index"
      :show="true"
      :type="msg.type"
      :closeable="true"
      slot-align="center"
      @g-close="onClose(index)"
    >
      <span v-html="msg.text" />
    </g-alert>
  </div>
</template>

<style lang="scss" scoped>
.flash-msg {
  position: sticky;
  top: 0;
  z-index: 100;

  a {
    color: inherit;
    text-decoration: underline;
  }
}
</style>
