import { defineStore } from 'pinia';
import useClientRegistriesStore from './clientRegistriesStore';
import defaultTranslations from '../config/defaultTranslations';

import request from '../modules/request';
import { buildOrConcatTemplate, getQueryParamValue } from '../modules/helperFunctions';

export default defineStore('globalStore', {
  state: () => ({
    flashMessages: [],
    portalLoading: false,
    globalFlow: 'signIn',
    pageHeader: '',
    translations: {},
    locale: 'en-US',
    service: '',
    securityUrl: '',
    privacyStatementUrl: '',
    termsOfUseUrl: '',
    accountManagementUrl: '',
    isChinaLocation: false,
    phoneRegistrationRequired: false,
    accountVerificationEnabled: false,
  }),
  getters: {
    getFlashMessages: (state) => state.flashMessages,
    getFlashMessageTranslation() {
      return (message) => this.getTranslations[message];
    },
    getPortalLoading: (state) => state.portalLoading,
    getGlobalFlow: (state) => state.globalFlow,
    getPrivacyUrl: (state) => state.privacyStatementUrl,
    getSecurityUrl: (state) => state.securityUrl,
    getTermOfUseUrl: (state) => state.termsOfUseUrl,
    getAccountManagementUrl: (state) => state.accountManagementUrl,
    getPageHeader() {
      return this.pageHeader || this.getTranslations.generalHeaderSignIn;
    },
    getTranslations: (state) => state.translations,
    getService: (state) => state.service,
    getLocale: (state) => state.locale,
    getLocaleCountry() {
      return this.getLocale.split('-')[1];
    },
    getDefaultCountrySelect() {
      if (this.getIsChinaLocation) {
        return 'CN';
      }
      return this.getLocaleCountry;
    },
    getIsChinaLocation: (state) => state.isChinaLocation,
    generateMfaSetupRequiredMessage() {
      return (translations, resetPassword) => {
        // drop http/https protocol from link text
        const linkText = this.accountManagementUrl.replace(/(^\w+:|^)\/\//, '');
        const messageTemplate = resetPassword
          ? translations.generalLoginPermanentMFARequiredResetPassword
          : translations.generalLoginPermanentMFARequired;

        return buildOrConcatTemplate(
          messageTemplate,
          [`<a href="${this.accountManagementUrl}">${linkText}</a>`],
          '{0}',
        );
      };
    },

    getPerMfaSetupRequiredMessage() {
      return this.generateMfaSetupRequiredMessage(this.translations, false);
    },

    getPerMfaSetupRequiredResetPwdMessage() {
      return this.generateMfaSetupRequiredMessage(this.translations, true);
    },

    getAccountVerificationFlagEnabled: (state) => state.accountVerificationEnabled,
    getPhoneRegistrationRequired: (state) => state.phoneRegistrationRequired,
  },
  actions: {
    setFlashMessage(payload) {
      const msg = {};
      [
        msg.type,
        msg.text,
      ] = payload;

      this.flashMessages.push(msg);
    },
    removeFlashMessage(index) {
      this.flashMessages = this.flashMessages.filter((msg, i) => i !== index);
    },
    resetFlashMessages() {
      const msgs = this.getFlashMessages;

      msgs.forEach((msg, index) => {
        this.removeFlashMessage(index);
      });
    },
    setPortalLoading(loading) {
      this.portalLoading = loading;
    },
    setGlobalFlow(newFlow) {
      this.globalFlow = newFlow;

      /* these need to be translated */
      if (newFlow === 'signIn') this.pageHeader = this.getTranslations.generalHeaderSignIn;
      if (newFlow === 'createAccount') this.pageHeader = this.getTranslations.createAccPageTitle;
    },
    setTranslations() {
      this.translations = window?.GarminAppBootstrap
        ? window?.GarminAppBootstrap?.messages
        : defaultTranslations;
    },
    setLocale() {
      const locale = window?.GarminAppBootstrap?.locale;
      if (locale) this.locale = locale.replace('_', '-');
    },
    setSecurityUrl() {
      this.securityUrl = window?.GarminAppBootstrap?.securityUrl;
    },
    setPrivacyUrl() {
      this.privacyStatementUrl = window?.GarminAppBootstrap?.privacyUrl;
    },
    setTermsOfUseUrl() {
      this.termsOfUseUrl = window?.GarminAppBootstrap?.termsOfUseUrl;
    },
    setAccountManagementUrl() {
      this.accountManagementUrl = window?.GarminAppBootstrap?.accountManagementUrl;
    },
    setIsChinaLocation() {
      this.isChinaLocation = window?.GarminAppBootstrap?.isChinaLocation;
    },
    setAccountVerificationEnabled() {
      this.accountVerificationEnabled = window?.GarminAppBootstrap?.accountVerificationEnabled;
    },
    setPhoneRegistrationRequired() {
      this.phoneRegistrationRequired = window?.GarminAppBootstrap?.phoneRegistrationRequired;
    },
    setService() {
      const serviceQuery = getQueryParamValue('service');
      let serviceVal;
      // Changes for NETAPP-39453 URL encoding enhancements
      if (serviceQuery !== window?.GarminAppBootstrap?.service) {
        serviceVal = window.GarminAppBootstrap.service;
      } else if (serviceQuery) {
        serviceVal = serviceQuery;
      }

      // need to encode the service value
      this.service = encodeURIComponent(serviceVal);
    },
    setRequest(config, customAlerts = {}) {
      // append required query params
      const serviceVal = this.getService;
      const service = serviceVal ? `&service=${serviceVal}` : '';
      const locale = this.getLocale;
      const { getClientId } = useClientRegistriesStore();
      const newQueries = `clientId=${getClientId}&locale=${locale}${service}`;
      const { url } = config;
      const separator = url.includes('?') ? '&' : '?';
      const newUrl = url + separator + newQueries;
      const newConfig = { ...config };
      newConfig.url = newUrl;

      return request(newConfig).then((data) => {
        if (!data) return data;

        const { message, type } = data.responseStatus;

        if (message.length) {
          let successMsg = this.getFlashMessageTranslation(message);

          if (successMsg) {
            if (type === 'PERMANENT_MFA_SETUP_REQUIRED') {
              successMsg = this.getPerMfaSetupRequiredMessage;
            }
            if (type === 'PERMANENT_MFA_SETUP_REQUIRED_RESET_PASSWORD') {
              successMsg = this.getPerMfaSetupRequiredResetPwdMessage;
            }

            this.resetFlashMessages();
            this.setFlashMessage([
              // set custom alert type if one is set for the current response type
              customAlerts[type] || 'success',
              successMsg,
            ]);
          } else {
            console.error(`Missing translation: ${message}`);
          }
        }

        return data;
      }).catch((error) => {
        console.error(`${error}`);
        let resData = {};
        let errorMsg = this.getTranslations.generalTextGenericErrorMsg;
        try {
          const { data } = error.response;
          const { message } = data.responseStatus;
          resData = data;
          const resMsg = this.getFlashMessageTranslation(message);

          if (resMsg) errorMsg = resMsg;
        } catch (e) {
          console.error(`Error calling ${url}: ${e}`);
        }

        this.resetFlashMessages();
        this.setFlashMessage([
          'error',
          errorMsg,
        ]);

        return resData;
      });
    },
  },
});
