import { defineStore } from 'pinia';
import useGlobalStore from './globalStore';
import baseUrls from '../router/config/baseUrls';
import redirectToLogoutServiceUrl from '../modules/redirectToLogoutServiceUrl';

const { apiUrl } = baseUrls;

/*
* This store has all contains data associated to consent
* To use this store import store and one of the getter function can be called as needed
* during the account creation process
* */
// if we decide to  add translation to login
// const bootStrap = () => window?.GarminAppBootstrap;
export default defineStore('logoutStore', {
  actions: {
    async logout() {
      const { setRequest, setPortalLoading } = useGlobalStore();
      setPortalLoading(true);
      const response = await setRequest(
        {
          method: 'POST',
          url: `${apiUrl}/logout`,
        },
      );
      setPortalLoading(false);
      const {
        responseStatus,
        logoutUrl,
      } = response || {};
      const { type } = responseStatus;
      if (logoutUrl !== null) {
        if (type === 'SUCCESSFUL') {
          redirectToLogoutServiceUrl(logoutUrl);
        }
      }
      return responseStatus;
    },
  },
});
