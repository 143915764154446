import { defineStore } from 'pinia';
import useGlobalStore from './globalStore';
import useConsentStore from './consentStore';

import baseUrls from '../router/config/baseUrls';

const { apiUrl } = baseUrls;
const mfaApiUrl = `${apiUrl}/mfa`;
const mfaSetupApiUrl = `${apiUrl}/mfaSetup`;

export default defineStore('mfaStore', {
  state: () => ({
    mfaRequired: false,
    setupMfa: false,
    customerMfaInfo: null,
    permMFAEmailSetup: false,
  }),
  getters: {
    getMfaRequired: (state) => state.mfaRequired,
    getSetupMfa: (state) => state.setupMfa,
    getPermMFAEmailSetup: (state) => state.permMFAEmailSetup,
    getCustomerMfaInfo: (state) => state.customerMfaInfo,
    getIsMfaFlow() {
      return this.getMfaRequired || this.getSetupMfa;
    },
    getMfaOption() {
      return this.getCustomerMfaInfo?.mfaLastMethodUsed || '';
    },
    getMfaEmail() {
      return this.getCustomerMfaInfo?.email || '';
    },
    getMfaPhone() {
      return this.getCustomerMfaInfo?.phoneNumber || '';
    },
    getMfaOptionIsEmail() {
      // default to email if option is not set
      return !this.getMfaOption || this.getMfaOption === 'email';
    },
    getMfaOptionIsPhone() {
      return this.getMfaOption === 'phone';
    },
    getHasMfaEmail() {
      return !!this.getMfaEmail.length;
    },
    getHasMfaPhone() {
      return !!this.getMfaPhone.length;
    },
    getMfaOptionValue() {
      return this.getMfaOptionIsPhone ? this.getMfaPhone : this.getMfaEmail;
    },
  },
  actions: {
    setMfaOption(option) {
      this.getCustomerMfaInfo.mfaLastMethodUsed = option;
    },
    setMfaPhone(phone) {
      this.getCustomerMfaInfo.phoneNumber = phone;
    },
    setMfaRequired(mfaRequired) {
      this.mfaRequired = mfaRequired;
    },
    setPermMFAEmailSetup(permMFAEmailSetup) {
      this.permMFAEmailSetup = permMFAEmailSetup;
    },
    setSetupMfa(setupMfa) {
      this.setupMfa = setupMfa;
    },
    setCustomerMfaInfo(info) {
      this.customerMfaInfo = { ...info };
    },
    setInitMfaData(payload) {
      /*
        need to call this even if type isn't an MFA type or customerMfaInfo is undefined
        so that any stale MFA data is cleared from the MFA store
      */
      const { type, customerMfaInfo, consentTypeList } = payload;

      this.setMfaRequired(type === 'MFA_REQUIRED');
      this.setSetupMfa(type === 'MFA_SETUP_REQUIRED' || type === 'PERMANENT_MFA_SETUP');
      this.setCustomerMfaInfo(customerMfaInfo || null);

      if (type === 'PERMANENT_MFA_SETUP') {
        this.setPermMFAEmailSetup(true);
      }
      /* Set consent data to be shown */
      if (consentTypeList && consentTypeList.length > 0) {
        const consentStore = useConsentStore();
        consentStore.setReconsentTypeList(consentTypeList);
      }
    },
    async performMfaCheck() {
      const { setRequest } = useGlobalStore();

      const response = await setRequest({
        method: 'GET',
        url: `${apiUrl}/performMfaCheck`,
      });

      const { responseStatus, customerMfaInfo, consentTypeList } = response;
      const { type } = responseStatus;

      // set or clear MFA data
      this.setInitMfaData({ type, customerMfaInfo, consentTypeList });

      return response;
    },
    async verifyMfaCode(payload) {
      const { setRequest } = useGlobalStore();
      const customAlerts = {
        MFA_CODE_INVALID: 'warning',
        MFA_CODE_EXPIRED: 'warning',
      };

      const response = await setRequest(
        {
          method: 'POST',
          url: `${mfaApiUrl}/verifyCode`,
          payload: {
            ...payload,
            mfaSetup: this.getSetupMfa,
          },
        },
        customAlerts,
      );

      return response;
    },
    async requestMfaCode() {
      const { setRequest } = useGlobalStore();

      const response = await setRequest({
        method: 'POST',
        url: `${mfaApiUrl}/sendCode`,
        payload: {
          mfaMethod: this.getMfaOption,
        },
      });

      return response;
    },
    async updateMfaInfo(payload) {
      const { setRequest } = useGlobalStore();
      const customAlerts = {
        INVALID_PHONE_NUMBER: 'warning',
      };

      const response = await setRequest(
        {
          method: 'POST',
          url: `${mfaApiUrl}/update`,
          payload,
        },
        customAlerts,
      );

      return response;
    },
    async confirmMfaSetup(payload) {
      const { setRequest } = useGlobalStore();

      const response = await setRequest({
        method: 'POST',
        url: `${mfaSetupApiUrl}/confirm`,
        payload,
      });

      return response;
    },
  },
});
