/* eslint-disable max-len */
export default {
  casEmbedSuccessPageTitle: 'Success',
  casLoginViewChangePasswordDesc: 'To ensure the security of your account, please choose a new password.',
  casLoginViewCreateAccount: 'Create One',
  casLoginViewDontHaveAccount: "Don't have an account?",
  casLoginViewGigyaNotSupported: 'Note: For security, we no longer support social network sign-in options for Android 4.3 or older.',
  casLoginViewMissingPassword: "It looks like you don't have a password yet. To continue, check your email for the temporary password we just sent you.",
  casLoginViewNewEmailAddress: 'Email Address',
  casLoginViewNewPassword: 'New Password',
  casLoginViewNotYou: '(Not You?)',
  casLoginViewOrSignInWith: 'Or Sign In with:',
  casLoginViewPageHeader: 'Sign In',
  casLoginViewPageHeaderChina: 'Garmin Account Sign In - Mainland China',
  casLoginViewPageHeaderCustomGarminConnect: 'Garmin Account Sign-In',
  casLoginViewPageTitle: 'GARMIN Authentication Application',
  casLoginViewReactivateAccount: 'You’ll need to reset your password to access your account. Please check your email for a temporary password. If you don’t see an email, use the “Forgot password?“ link to request one.',
  casLoginViewRenewPassword: 'We’ve updated our password requirements to help protect our customer’s accounts. Looks like your password does not meet our new guidelines. Please update your password now.',
  casLoginViewRetypeEmailAddress: 'Retype Email Address',
  casLoginViewRetypeNewPassword: 'Retype New Password',
  casLoginViewSetDisplayNameDesc: 'Please choose a display name for this account.',
  casLoginViewSetEmailAddressDesc: 'Please enter an email address for this account.',
  casLoginViewSignInWithDiffAccount: 'Sign in with a Different Account',
  casLoginViewVerificationCodeMessage: 'Your account with phone number {0} has not been verified yet. Please request a new verification code by clicking the link below.',
  casLoginViewWelcomeBack: 'Welcome back, {0}.',
  codeEntryCodeEntryLabel: 'Security code',
  codeEntryCodeSent: 'A new code has been sent. You can request another code in 30 seconds.',
  codeEntryError: 'Something went wrong. Please try again.',
  codeEntryExpired: 'The code you entered is expired. Please request a new code.',
  codeEntryHavingTrouble: 'Get help',
  codeEntryHavingTroubleLink: 'https://support.garmin.com/{0}/?faq=uGHS8ZqOIhA0usBzBMdJu7',
  codeEntryHeading: 'Enter security code',
  codeEntryInvalid: 'Invalid code. Please enter a valid code.',
  codeEntryMaxLimit: "You have reached the maximum amount of codes requested. Please use a code you've received or wait 24 hours and try again.",
  codeEntryRequestNewCode: 'Request a new code',
  codeEntrySentCode: 'Code sent to',
  codeEntryVerifyWithDifferentMethod: 'Choose a different way to verify',
  createAccCompleteFieldsBelow: 'Please complete the fields below:',
  createAccConnectEmailSignupLabel: 'Sign Up for Email:',
  createAccConnectEmailSignupText: 'Send me email about promotions and new products.',
  createAccDisplayNameExists: 'An account with this display name already exists. If you want to create a new account, enter a different display name.',
  createAccEmailExists: 'An account with this email address already exists. Please {0}sign in{1} to that account, or, if you want to create a new account, enter a different email address.',
  createAccMobileUsernameExists: 'An account with this username already exists. Please sign in to that account, or, if you want to create a new account, enter a different username.',
  createAccOrCompleteFieldsBelow: 'Or, you can complete the fields below:',
  createAccOrCreateWithSocial: 'Or, create your account using:',
  createAccPageTitle: 'Create An Account',
  createAccPleaseAuthenticate: 'Please authenticate',
  createAccSendNewCode: '{0}Send a new code{1}',
  createAccUpdatePhoneNumber: 'Update Phone number',
  createAccUsernameExists: 'An account with this username already exists. Please {0}sign in{1} to that account, or, if you want to create a new account, enter a different username.',
  createAccVerificationCodeMessage: 'A verification code has been sent to your phone number: {0}. Use the code to login.',
  createOrLinkCreateAccountLink: 'This is my first time signing in to Garmin.',
  createOrLinkDoHaveGarminAccount: 'I do have a Garmin Account.',
  createOrLinkEnterEmailDesc: "Enter your Garmin account's email address to link the accounts. If you do not have a Garmin account, enter your email address to create one now:",
  createOrLinkLinkAccountLink: "I'd like to use my social network to access it.",
  createOrLinkNoGarminAccount: "I don't have a Garmin Account.",
  createOrLinkPageTitle: 'Create Or Link Social Account',
  createOrLinkWhichBestDescribesYou: 'Now we will connect your social network account to Garmin. Which best describes you?',
  createSocAccBindingExists: 'The Garmin Account has been bound to a third-party account of the same type. Please change to another Garmin Account for binding.',
  createSocAccContinue: 'Continue',
  createSocAccCreateSocAccDesc: 'Before your first sign in, we need a little information. What you enter here will not affect your {0} account. If you already have a Garmin account, please {1}sign in{2}.  When you are finished, please remember to sign out of both your Garmin account and your social network account if you use a shared computer.',
  createSocAccEmailExists: 'It looks like you already have an account. You may {0}sign in{1} to connect your {2} account to your Garmin account. Or, start a separate Garmin account by entering a different email address.',
  createSocAccPageTitle: 'Create Social Account',
  createAccSecurityCodeMaxLimit: 'We are currently experiencing technical difficulties.  Please try again in 24 hours.',
  embedUsage: 'Usage: {0}?{1}=<{1}>&{2}=<{2}>...',
  errorAuthenticationCredentialsUnsupported: "The credentials you provided are not supported Garmin's authentication system.",
  errorAuthenticationEmailAddressInvalid: 'It looks like the email address we have on file for you is invalid. Please update it.',
  forgotPswPageTitle: 'Forgot Password',
  fourOhFourHeader: "Sorry, we can't find that page",
  fourOhFourSubHeader: 'Let us help you find your way',
  generalAccountDisplayNameHint: '(Your public activities show this name.)',
  generalAccountPasswordRules: '(Password must be at least 8 characters: 1 uppercase, 1 lowercase and 1 number.)',
  generalButtonCancel: 'Cancel',
  generalButtonClose: 'Close',
  generalButtonCreateAccount: 'Create Account',
  generalButtonGoBack: 'Go back',
  generalButtonNext: 'Next',
  generalButtonAcceptContinue: 'Accept & Continue',
  generalButtonDecline: 'Decline',
  generalButtonNo: 'No',
  generalButtonRecoverPassword: 'Recover Password',
  generalButtonSave: 'Save',
  generalButtonSend: 'Send',
  generalButtonSignIn: 'Sign In',
  generalButtonSignOut: 'Sign out',
  generalButtonUpdate: 'Update',
  generalButtonYes: 'Yes',
  generalHeaderChangePassword: 'Change Password',
  generalHeaderForgotPassword: 'Forgot Password?',
  generalHeaderPleaseCheck: 'Please check the following',
  generalHeaderReactivateAccount: 'Reactivate Account',
  generalHeaderSetDisplayName: 'Choose Display Name',
  generalHeaderSetEmailAddress: 'Enter Email Address',
  generalHeaderSignIn: 'Sign In',
  generalLoginAccountDisabled: 'Your account has been disabled.',
  generalLoginAccountLocked: 'Your account has been locked. Please use the "Forgot Password?" link to request a temporary password.',
  generalLoginAccountProblem: 'There is a problem with your account. Please contact {0}Product Support{1} for help.',
  generalLoginForgotUsernameTooltip: 'Use your email address to sign in if you forgot your username. If you did not save an email address in your account, or do not remember it, please contact {0}Product Support{1}.',
  generalLoginInvalidUsernameOrPassword: 'Invalid sign in. (Passwords are case sensitive.)',
  generalLoginMobileAccountDisabled: 'Your account has been disabled.',
  generalLoginContinueAsGuest: 'Continue as Guest',
  generalLoginMobileAccountLocked: 'Your account has been locked. Please reset your password.',
  generalLoginMobileAccountProblem: 'There is a problem with your account. Please contact Product Support for help.',
  generalLoginMobileProblem: "We're unable to access your account. Please try again later, or contact Product Support.",
  generalLoginPermanentMFARequired: 'Two-step verification is required to access your account. Please visit {0} to log in and complete this setup.',
  generalLoginPermanentMFARequiredResetPassword: 'Password has been updated. Two-step verification is required to access your account. Please visit {0} to log in and complete this setup.',
  generalLoginProblem: "We're unable to access your account. Please try again later, or contact {0}Product Support{1}.",
  generalLoginResetPasswordAdditional: 'For additional help, contact {0}Product Support{1}.',
  generalLoginResetPasswordDesc: 'Enter the email address associated with your account.',
  generalLoginResetPasswordUnexpectedError: 'Something went wrong. Please try again.',
  generalLoginSignInWithDiffAccount: 'Sign in with a Different Account',
  generalLoginSocialAccountAlreadyLinked: 'Your Garmin account is already connected to a {0} account. Would you like to disconnect from that account and connect to this new {0} account?',
  generalLoginSocialFacebook: 'Sign in with Facebook',
  generalLoginSocialQq: 'Sign in with QQ',
  generalLoginSocialRenren: 'Sign in with Renren',
  generalLoginSocialSina: 'Sign in with Sina',
  generalLoginSocialWechat: 'Sign in with WeChat',
  generalTextCapsLockOn: 'Caps lock is on.',
  generalTextContactUs: 'Contact Us',
  generalTextCreateAccount: 'Create an Account',
  generalTextDisplayName: 'Display Name',
  generalTextDisplayNameHint: 'Your public activities show this name.',
  generalTextEmailAddress: 'Email Address',
  generalTextEmailAddressHint: "You'll use your email address to sign in.",
  generalTextEmailOrUsername: 'Email',
  generalTextForgot: '(Forgot?)',
  generalTextFullName: 'Full Name',
  generalTextGenericErrorMsg: 'An unexpected error has occurred.',
  generalTextGlobalLogout: 'Sign me out of all Garmin sites and apps.',
  generalTextHide: 'Hide',
  generalTextLocationPrompt: 'Country of Residence',
  generalTextLocationPromptCountryOrRegion: 'Country/Region',
  generalTextLocationPromptHint: 'Your country or region of residence helps us comply with data privacy regulations and is never shared with others.',
  generalTextLocationSelectOne: 'Select one...',
  generalTextMobilePhoneNumber: 'Phone',
  generalTextMobilePhoneNumberChange: '{0}Change Phone Number{1}',
  generalTextName: 'Name',
  generalTextNameHint: 'Enter your name.',
  generalTextOrCreateAccount: 'Or create your account using',
  generalTextPassword: 'Password',
  generalTextPasswordHint: 'Minimum password length is 8 characters. Please use at least 1 uppercase letter, 1 lowercase letter and 1 number.',
  generalTextPhoneNumber: 'Phone Number',
  generalTextPhoneNumberChange: '{0}Change Your Phone Number{1}',
  generalTextPhoneNumberHint: 'Enter your phone number.',
  generalTextPleaseCheck: 'Please check the following',
  generalTextRememberMe: 'Remember Me',
  generalTextRememberThisBrowser: 'Remember this browser',
  generalTextRequired: 'Required',
  generalTextRetypeEmailHint: 'Enter your desired email again.',
  generalTextRetypePassword: 'Retype Password',
  generalTextRetypePasswordHint: 'Enter your desired password again.',
  generalTextShow: 'Show',
  generalTextTempPasswordSent: 'If the email address you entered matches one we have on file, we will send you a temporary password to access your account.',
  generalTextUsername: 'Username',
  generalTextUsernameHint: 'We suggest using your email address.',
  generalTextVerificationCode: 'Verification Code',
  generalValidationDisplayNameAlreadyTaken: 'Sorry, this display name is already taken. Please choose another.',
  generalValidationDisplayNameInvalidChars: "Your display name can't contain spaces or any of the following characters: {0}",
  generalValidationDisplayNameMobileAlreadyTaken: 'This display name already exists.',
  generalValidationDisplayNameReqd: 'Display Name is required.',
  generalValidationDisplayNameTooLong: 'Display Name must be 100 characters or fewer.',
  generalValidationDisplayNameTooShort: 'Display Name must be at least 4 characters.',
  generalValidationEmailAlreadyTaken: 'This email address is already in use.  Please {0}sign in{1} with this email address or enter another.',
  generalValidationEmailDontMatch: 'Email addresses do not match.',
  generalValidationEmailInvalid: 'Email address is invalid.',
  generalValidationEmailorUsernameReqd: 'Email is required.',
  generalValidationEmailPendingDelete: 'An account with this email address is in the process of being deleted',
  generalValidationEmailReqd: 'Email Address is required.',
  generalValidationEmailTooLong: 'Email Address must be 100 characters or fewer.',
  generalValidationLocationReqd: 'Location is required.',
  generalValidationMaxLogins: 'Maximum number of logins exceeded. Please try again later.',
  generalValidationNameInvalid: 'Please use letters only for your name.',
  generalValidationNameReqd: 'Name is required.',
  generalValidationPasswordInvalidChars: 'Minimum password length is 8 characters. Please use at least 1 uppercase letter, 1 lowercase letter and 1 number. Forward slashes, spaces and double quotes are not allowed.',
  generalValidationPasswordReqd: 'Password is required.',
  generalValidationPasswordsBothReqd: 'Please specify your password in both fields.',
  generalValidationPasswordsDontMatch: 'Passwords do not match.',
  generalValidationPasswordTooLong: 'Password must be 50 characters or less.',
  generalValidationPasswordTooShort: 'Password must be at least 8 characters.',
  generalValidationPhoneNumberInvalid: 'Phone number must be exactly 11 digits long.',
  generalValidationPhoneNumberReqd: 'Phone Number is required to send verification code to your phone.',
  generalValidationPhoneNumberWrongLength: 'Phone number must be exactly 11 digits long.',
  generalValidationUsernameInvalidChars: 'Please use only letters and numbers for your username.',
  generalValidationUsernameReqd: 'Username is required.',
  generalValidationUsernameTooLong: 'Username must be 100 characters or fewer.',
  generalValidationUsernameTooShort: 'Username must be at least 4 characters.',
  generalValidationVerificationCodeDontMatch: "Verification code doesn't match.",
  generalValidationVerificationCodeNewCode: 'A new verification code has been sent.',
  generalValidationVerificationCodeReqd: 'Verification code required.',
  generalValidationVerificationCodeWrongLength: 'Verification code must be exactly 8 characters long.',
  generateMfacodeError: 'Unable to generate MFA code',
  INVALIDREQUEST: "'service' and 'ticket' parameters are both required",
  INVALIDREQUESTPROXY: "'pgt' and 'targetService' parameters are both required",
  INVALIDSERVICE: "ticket ''{0}'' does not match supplied service.  The original service was ''{1}'' and the supplied service was ''{2}''.",
  INVALIDTICKET: "ticket ''{0}'' not recognized",
  INVALIDTICKETSPEC: 'Ticket failed validation specification. Possible errors could include attempting to validate a Proxy Ticket via a Service Ticket validator, or not complying with the renew true request.',
  legalAcceptPrivacyPolicy: "I have read Garmin's Privacy Policy.",
  legalAcceptTerms: 'I have read and agree to the Terms of Use.',
  legalChinaAcceptTerms: 'I Accept the Garmin Terms of Use and Privacy Statement.',
  legalConfirmAge: 'I am at least {0} years old.',
  legalImpliedAcceptTerms: 'By clicking the button below, you acknowledge that you have read and agree to the Garmin {0}Terms of Use{1} and {2}Privacy Statement{3}.',
  legalPrivacyPageTitle: 'Products and Application Privacy Statement',
  legalPrivacyStatement: 'We take your privacy seriously. Our {0}Privacy Policy{1} is available for your review.',
  legalTermsOfUse: 'Terms of Use',
  legalTermsPageTitle: 'Garmin Terms of Use',
  legalValidationAcceptTermsError: 'Please accept the Garmin Terms of Use and Privacy Statement.',
  linkAccErroLinkingSocialAcc: 'Error Linking Social Account.',
  linkAccLinkAccDesc: 'Sign in to connect your social network account to your Garmin account. You will only need to do this once. If you do not have an account you can {0}continue without signing in{1}.  When you are finished, please remember to sign out of both your Garmin account and your social network account if you use a shared computer.',
  linkAccPageTitle: 'Link Social Account',
  loginChooseMfaMethodPageTitle: 'Choose the MFA method',
  loginEnterMfaCodePageTitle: 'Enter MFA code for login',
  mfaButtonContinue: 'Continue',
  mfaDifferentNumber: 'Use a different number',
  mfaEmail: 'Email',
  mfaPhoneNumberEntryCountryEuropeanUnion: 'EU (+3)',
  mfaPhoneNumberEntryCountryUnitedKingdom: 'United Kingdom (+2)',
  mfaPhoneNumberEntryCountryUnitedStates: 'United States (+1)',
  mfaPhoneNumberEntryDisplayTitle: 'Phone number',
  mfaPhoneNumberEntryHeadingOne: 'Country/region',
  mfaPhoneNumberEntryHeadingThree: 'Phone number',
  mfaPhoneNumberEntryHeadingTwo: 'Area code',
  mfaPhoneNumberEntryInvalidInputError: 'Something went wrong. Please try again.',
  mfaPhoneNumberEntryInvalidNumberError: 'Please enter a valid phone number',
  mfaPhoneNumberEntryPageTitle: 'Enter Your Phone Number',
  mfaPhoneNumberEntryPrivacyPolicy: 'Privacy Policy',
  mfaPhoneNumberEntryPrivacyStatement: '{0}Privacy Policy{1}',
  mfaPhoneNumberEntryTextOne: "Enter your phone number and we'll send your security code.",
  mfaPhoneNumberEntryOptInTextOne: "Enter your phone number to enroll in two-step verification, and we'll send your security code.",
  setupMfaOptInContent: 'By accepting this request, you agree to receive text messages at the phone number provided above. You can reply to these text messages with HELP for Help or STOP to stop receiving messages at any time. Message and data rates may apply. Message frequency may vary. View our {0}Privacy Policy{1} and {2}Terms of Use{3}.',
  mfaSelectionAddPhone: 'Add a phone number',
  mfaSelectionContent: "We'll send a security code to your email or phone.",
  mfaSelectionContentPermMfa: "We'll send a security code to your email.",
  mfaSelectionDifferentPhone: 'Use a different number',
  mfaSelectionHeading: 'How do you want to receive your code?',
  mfaSelectionPageTitle: 'Select the MFA method',
  mfaTextMessage: 'Text message',
  mfaVerificationPageTitle: '2 Step Authentication',
  mobileCasLoginViewPageHeaderChina: 'Garmin Account Sign In - Mainland China',
  mobileCasLoginViewPageTitle: 'Login',
  mobileCreateAccChangePhoneNumber: 'Change Phone number',
  mobileCreateAccEnterPhoneNumber: 'Enter Phone Number',
  mobileCreateaccOrCreateUsing: 'Or create your account using',
  mobileCreateAccRequestNewCode: '{0}Request a new code{1}',
  mobileCreateAccVerificationCodeMessage: 'A verification code has been sent to your phone number: {0}.',
  mobileCreateAccVerifyYourAccount: 'Verify Your Account',
  mobileForgotPswPageTitle: 'Forgot Password',
  mobileVerifyAccountPageTitle: 'Verify Account',
  mobileVerifyAccountSetPhonePageTitle: 'Update Phone Number',
  mobileVerifyEnterCode: 'Enter Verification Code',
  mobileVerifyVerificationCodeMessage: 'A verification code has been sent to your phone number:',
  mobileVerifyVerifyMessage: 'Request a new code',
  noCustomerFoundError: 'No Customer found',
  screenLogoutHeader: 'Logout successful',
  screenLogoutRedirect: 'The service from which you arrived has supplied a <a href="{0}">link you may follow by clicking here</a>.',
  screenLogoutSecurity: 'For security reasons, exit your web browser.',
  screenLogoutSuccess: 'You have successfully logged out of the Central Authentication Service.',
  setupEnterMfaCodePageTitle: 'Enter the MFA code for setup',
  setupMfaConfirmationContent: "Next time you sign in, you'll be prompted to enter your password, followed by a security code that we will send you in an email or text message.",
  setupMfaConfirmationContentTitle: 'Two-step verification is enabled',
  setupMfaRequiredContent: 'To continue, you’ll need to set up two-step verification for your account. \n\nTwo-step verification adds an extra layer of protection to your account. Whenever you sign in to Garmin.com to make a purchase or use one of our apps, you’ll be prompted to enter your password, followed by a security code that we will send you in an email or text message. \n\nWithout setting up two-step verification, you won’t be able to sign in to this application. You will be able to sign in on Garmin websites or apps that do not require two-step verification.',
  setupMfaRequiredContentPermMfa: 'To continue, you’ll need to set up two-step verification for your account. \n\nTwo-step verification adds an extra layer of protection to your account. Whenever you sign in to Garmin.com to make a purchase or use one of our apps, you’ll be prompted to enter your password, followed by a security code that we will send you in an email. \n\nWithout setting up two-step verification, you won’t be able to sign in to this application',
  setupMfaRequiredContentTitle: 'Two-step verification required',
  setupMfaRequiredPageTitle: 'MFA required',
  verifyAccountPageTitle: 'Verify Account',
  verifyAccountSetPhonePageTitle: 'Update Phone Number',
  setupMfaConfirmationPageTitle: 'MFA confirmation',
  generalLoginAlreadyHaveAnAccount: 'Already have an account?',
  generalLoginAreaCodeIsInvalid: 'Area Code is invalid',
  generalLoginThisFieldIsRequired: 'This field is required',
  generalLoginPhoneNumberIsInvalid: 'Phone number is invalid',
  generalLoginPhoneNumberFormat: 'Format: 86 XXX XXXX XXXX',
  generalLoginYourSessionHasExpired: 'Your session has expired.',
  footerTermsOfUse: 'Terms of Use',
  footerPrivacy: 'Privacy',
  footerSecurity: 'Security',
  footerCopyRight: 'Copyright \u00A9 1996-{0} Garmin Ltd. or its subsidiaries',
  consentFormPageTitle: 'Privacy Policy Update',
  reconsentButtonContinue: 'Continue',
};
