/**
 *
 * @param path
 * @param SAMLResponse
 */
function postForm(path, SAMLResponse) {
  const method = 'post';
  const form = document.createElement('form');
  form.setAttribute('method', method);
  form.setAttribute('action', path);
  let hiddenField = document.createElement('input');
  hiddenField.setAttribute('type', 'hidden');
  hiddenField.setAttribute('name', 'SAMLResponse');
  hiddenField.setAttribute('value', SAMLResponse);
  form.appendChild(hiddenField);
  hiddenField = document.createElement('input');
  hiddenField.setAttribute('type', 'hidden');
  hiddenField.setAttribute('name', 'RelayState');
  hiddenField.setAttribute('value', window.GarminAppBootstrap.relayState);
  form.appendChild(hiddenField);
  document.body.appendChild(form);
  form.submit();
}
export default (serviceURL, samlResponse) => {
  // Changes for NETAPP-39453 URL encoding enhancements
  const decodedServiceUrl = decodeURIComponent(serviceURL);
  // const separator = decodedServiceUrl.includes('?') ? '&' : '?';
  let computedServiceURL = '';
  if (serviceURL.includes('?')) {
    const splitURL = serviceURL.split('?');
    const serviceURLPath = decodeURIComponent(splitURL[0]);
    const serviceURLParams = splitURL[1];
    if (serviceURLParams != null) {
      computedServiceURL = `${serviceURLPath}?${(serviceURLParams)}`;
    }
  } else {
    computedServiceURL = decodedServiceUrl;
  }
  // window.location.href = `${computedServiceURL}${separator}SAMLResponse=${samlResponse}`;
  postForm(`${computedServiceURL}`, `${samlResponse}`);
};
