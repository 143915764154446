<script setup>
/* Imports */
import { useRouter } from 'vue-router';
import useGlobalStore from '../../store/globalStore';
import PortalButton from '../../components/PortalButton/PortalButton.vue';
import PortalPrimaryHeader from '../../components/PortalPrimaryHeader/PortalPrimaryHeader.vue';
import PortalSecondaryHeader from '../../components/PortalSecondaryHeader/PortalSecondaryHeader.vue';

/* Route */
const router = useRouter();

/* Stores */
const globalStore = useGlobalStore();

const handleClick = () => {
  const locale = globalStore.getLocale;

  router.push({ name: 'SignIn', params: { locale } });
};
</script>

<template>
  <PortalPrimaryHeader :title="globalStore.translations.fourOhFourHeader" />
  <PortalSecondaryHeader :title="globalStore.translations.fourOhFourSubHeader" />
  <PortalButton
    class="not-found__sign-in-btn"
    theme="ocean-blue"
    size="large"
    @click="handleClick"
  >
    {{ globalStore.translations.generalButtonSignIn }}
  </PortalButton>
</template>

<style lang="scss">
.not-found__sign-in-btn {
  margin-top: 1rem;
}
</style>
