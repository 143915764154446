import { defineStore } from 'pinia';
import useGlobalStore from './globalStore';
import useMfaStore from './mfaStore';

import baseUrls from '../router/config/baseUrls';
import redirectToServiceUrl from '../modules/redirectToServiceUrl';
import useConsentStore from './consentStore';
import useViewStore from './viewStore';

const { apiUrl } = baseUrls;
const customAlertsPermMfa = {
  PERMANENT_MFA_SETUP_REQUIRED: 'warning',
  PERMANENT_MFA_SETUP_REQUIRED_RESET_PASSWORD: 'warning',
};

export default defineStore('signinStore', {
  actions: {
    async checkLogin(setDefaultView) {
      const { setRequest, setGlobalFlow, setPortalLoading } = useGlobalStore();
      const mfaStore = useMfaStore();
      const consentStore = useConsentStore();
      const viewStore = useViewStore();

      setPortalLoading(true);
      const response = await setRequest(
        {
          method: 'GET',
          url: `${apiUrl}/checkLogin`,
        },
        customAlertsPermMfa,
      );
      setPortalLoading(false);

      const {
        serviceURL,
        serviceTicketId,
        responseStatus,
        customerMfaInfo,
        consentTypeList,
      } = response;
      const { type } = responseStatus;

      // set or clear MFA data
      const { setInitMfaData } = mfaStore;
      setInitMfaData({ type, customerMfaInfo, consentTypeList });

      // Redirect to MFA if required
      if (mfaStore.getIsMfaFlow) {
        setGlobalFlow('signIn');
        this.router.push({ name: 'Mfa' });

        return;
      }
      if (type === 'PERMANENT_MFA_SETUP_REQUIRED') {
        viewStore.setCurrentView('signIn', false);
        return;
      }
      // Redirect to  Reconsent for RememberMe users.
      if (type === 'RECONSENT_REQUIRED') {
        consentStore.setReconsentTypeList(consentTypeList);
        viewStore.setCurrentView('reConsentForm');

        return;
      }

      if (type === 'SUCCESSFUL') redirectToServiceUrl(serviceURL, serviceTicketId);

      setDefaultView();
    },
    async setSignIn(payload) {
      const { setRequest } = useGlobalStore();
      const customAlerts = {
        ACCOUNT_LOCKED: 'warning',
        ACCOUNT_DEACTIVATED: 'warning',
        ACCOUNT_DISABLED: 'warning',
        PERMANENT_MFA_SETUP_REQUIRED: 'warning',
        PERMANENT_MFA_SETUP_REQUIRED_RESET_PASSWORD: 'warning',
      };

      const response = await setRequest(
        {
          method: 'POST',
          url: `${apiUrl}/login`,
          payload,
        },
        customAlerts,
      );
      const consentStore = useConsentStore();
      const { responseStatus, customerMfaInfo, consentTypeList } = response;
      if (!responseStatus) {
        return {};
      }
      const { type } = responseStatus;
      consentStore.setReconsentTypeList(consentTypeList);

      // set or clear MFA data
      const { setInitMfaData } = useMfaStore();
      setInitMfaData({ type, customerMfaInfo });

      return response;
    },
    async setForgotPassword(payload) {
      const { setRequest } = useGlobalStore();

      const response = await setRequest({
        method: 'POST',
        url: `${apiUrl}/forgotPassword`,
        payload,
      });

      return response;
    },
    async setNewPassword(payload) {
      const { setRequest } = useGlobalStore();
      const response = await setRequest(
        {
          method: 'POST',
          url: `${apiUrl}/setNewPassword`,
          payload,
        },
        customAlertsPermMfa,
      );

      const { responseStatus, customerMfaInfo, consentTypeList } = response;
      const { type } = responseStatus || {};
      // Set MFA flow
      const { setInitMfaData } = useMfaStore();
      setInitMfaData({ type, customerMfaInfo });

      const consentStore = useConsentStore();
      consentStore.setReconsentTypeList(consentTypeList);
      return response;
    },
  },
});
